import { languageOptions } from "@/static/languages";

function getLanguage(locale) {
  return languageOptions.find(
    (language) => language.locale.toLowerCase() === locale.toLowerCase()
  );
}

export default {
  namespaced: true,
  state: {
    language: null,
    languages: [],
  },
  mutations: {
    changeLanguage: (state, locale) => {
      state.language = getLanguage(locale);
    },
    setLanguages: (state, languages) => {
      state.languages = languages
        .map((lang) => getLanguage(lang.locale))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  actions: {
    changeLanguage: (context, locale) => {
      context.commit("changeLanguage", locale);
    },
    setLanguages: (context, languages) => {
      context.commit("setLanguages", languages);
    },
  },
};
