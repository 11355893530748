const languages = {
  "af-ZA": ["Afrikaans", "Afrikaans"],
  ar: ["العربية", "Arabic"],
  "bg-BG": ["Български", "Bulgarian"],
  "ca-AD": ["Català", "Catalan"],
  "cs-CZ": ["Čeština", "Czech"],
  "cy-GB": ["Cymraeg", "Welsh"],
  "da-DK": ["Dansk", "Danish"],
  "de-AT": ["Deutsch (Österreich)", "German (Austria)"],
  "de-CH": ["Deutsch (Schweiz)", "German (Switzerland)"],
  "de-DE": ["Deutsch (Deutschland)", "German (Germany)"],
  "el-GR": ["Ελληνικά", "Greek"],
  "en-GB": ["English (UK)", "English (UK)"],
  "en-US": ["English (US)", "English (US)"],
  "es-CL": ["Español (Chile)", "Spanish (Chile)"],
  "es-ES": ["Español (España)", "Spanish (Spain)"],
  "es-MX": ["Español (México)", "Spanish (Mexico)"],
  "et-EE": ["Eesti keel", "Estonian"],
  eu: ["Euskara", "Basque"],
  "fa-IR": ["فارسی", "Persian"],
  "fi-FI": ["Suomi", "Finnish"],
  "fr-CA": ["Français (Canada)", "French (Canada)"],
  "fr-FR": ["Français (France)", "French (France)"],
  "he-IL": ["עברית", "Hebrew"],
  "hi-IN": ["हिंदी", "Hindi"],
  "hr-HR": ["Hrvatski", "Croatian"],
  "hu-HU": ["Magyar", "Hungarian"],
  "id-ID": ["Bahasa Indonesia", "Indonesian"],
  "is-IS": ["Íslenska", "Icelandic"],
  "it-IT": ["Italiano", "Italian"],
  "ja-JP": ["日本語", "Japanese"],
  "km-KH": ["ភាសាខ្មែរ", "Khmer"],
  "ko-KR": ["한국어", "Korean"],
  "lt-LT": ["Lietuvių kalba", "Lithuanian"],
  "lv-LV": ["Latviešu", "Latvian"],
  "mn-MN": ["Монгол", "Mongolian"],
  "nb-NO": ["Norsk bokmål", "Norwegian (Bokmål)"],
  "nl-NL": ["Nederlands", "Dutch"],
  "nn-NO": ["Norsk nynorsk", "Norwegian (Nynorsk)"],
  "pl-PL": ["Polski", "Polish"],
  "pt-BR": ["Português (Brasil)", "Portuguese (Brazil)"],
  "pt-PT": ["Português (Portugal)", "Portuguese (Portugal)"],
  "ro-RO": ["Română", "Romanian"],
  "ru-RU": ["Русский", "Russian"],
  "sk-SK": ["Slovenčina", "Slovak"],
  "sl-SI": ["Slovenščina", "Slovenian"],
  "sr-RS": ["Српски / Srpski", "Serbian"],
  "sv-SE": ["Svenska", "Swedish"],
  "th-TH": ["ไทย", "Thai"],
  "tr-TR": ["Türkçe", "Turkish"],
  "uk-UA": ["Українська", "Ukrainian"],
  "vi-VN": ["Tiếng Việt", "Vietnamese"],
  "zh-CN": ["中文 (中国大陆)", "Chinese (PRC)"],
  "zh-TW": ["中文 (台灣)", "Chinese (Taiwan)"],
};

const languageOptions = Object.keys(languages)
  .map((key) => {
    return {
      locale: key,
      name: languages[key][0],
    };
  })
  .sort((a, b) => a.name.localeCompare(b.name));

export { languageOptions };
