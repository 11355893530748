import "babel-polyfill";
import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "@/router/";
import store from "./store/";
import { getMessage } from "@/static/translations";
import "@/sass/screen.scss";
Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    $t: function (key) {
      if (!store.state.language.language) {
        return getMessage("en-US", key);
      }
      return getMessage(store.state.language.language.locale, key);
    },
  },
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
