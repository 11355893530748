<template>
  <v-dialog
    id="confirmation-dialog"
    v-model="dialog"
    :max-width="options.width"
    persistent
  >
    <v-card>
      <v-card-title class="primary white--text py-3 px-5">{{
        title
      }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-3 px-5">{{ message }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-3 px-5">
        <v-btn color="secundairy" text @click.native="cancel">{{
          $t("cancel")
        }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click.native="agree">{{
          $t("continue")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: "primary",
      width: 320,
    },
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
