<template>
  <v-app ref="mainapp" :style="styles" :class="appClasses">
    <component :is="layout" :ref="layout"></component>
    <app-snackbar ref="snackbar"></app-snackbar>
    <confirm-dialog ref="confirm"></confirm-dialog>
    <app-loader-dialog ref="loader"></app-loader-dialog>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AppLayout from "./layouts/AppLayout";
import SimpleLayout from "./layouts/SimpleLayout";
import ConfirmDialog from "@/components/globals/ConfirmDialog";
import AppLoaderDialog from "@/components/globals/AppLoaderDialog";
import AppSnackbar from "@/components/globals/AppSnackbar";

const default_layout = "simple-layout";

export default {
  name: "App",
  components: {
    AppLayout,
    SimpleLayout,
    ConfirmDialog,
    AppSnackbar,
    AppLoaderDialog,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || default_layout;
    },
    appClasses() {
      if (!this.appStyles) {
        return null;
      }
      const appClasses = !!this.appStyles.font ? "custom-font" : "";
      return appClasses;
    },
    styles() {
      if (!this.appStyles) {
        return null;
      }
      const appStyles = {
        "--v-primary-base": this.appStyles.primaryColor,
        "--main-bg-color": this.appStyles.backgroundColor,
        "--container-bg-color": this.appStyles.containerBgColor,
        "--app-font-family": this.appStyles.font,
      };
      return appStyles;
    },
    ...mapState(["hotel"]),
    ...mapState("appStyles", ["appStyles"]),
    ...mapState("language", ["language", "languages"]),
  },
  created() {
    this.setNoImageCache(new Date().getTime());
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
    this.$root.$snackbar = this.$refs.snackbar.open;
    this.$root.$loader = this.$refs.loader;
  },
  methods: {
    ...mapActions(["setNoImageCache"]),
  },
};
</script>
