<template>
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 291.511 291.511"
    class="custom-icon"
    style="enable-background: new 0 0 22 22"
  >
    <g>
      <path
        d="M286.146,78.5h-13.538H16.367H5.375C2.406,78.5,0,80.904,0,83.875c0,2.974,2.406,5.375,5.375,5.375h5.617v47.465
		c0,26.405,21.271,47.9,47.567,48.336c-0.087,4.631-0.908,10.931-4.005,14.437c-1.677,1.891-3.863,2.771-6.89,2.771
		c-5.163,0-9.016-1.155-11.145-3.327c-2.134-2.179-2.103-4.908-2.103-4.93c0.184-2.966-2.074-5.513-5.042-5.696
		c-2.874-0.1-5.509,2.079-5.692,5.045c-0.043,0.729-0.279,7.323,4.908,12.845c4.256,4.516,10.669,6.814,19.065,6.814
		c6.071,0,11.236-2.21,14.94-6.394c5.535-6.253,6.664-15.454,6.735-21.519h160.267c0.914,0,1.807-0.088,2.709-0.13
		c0.064,6.059,1.165,15.349,6.751,21.648c3.706,4.184,8.871,6.394,14.94,6.394c8.398,0,14.808-2.288,19.06-6.814
		c5.193-5.511,4.957-12.115,4.908-12.85c-0.178-2.919-2.54-4.897-5.584-4.999c-2.925,0.127-5.214,2.626-5.15,5.555
		c0,0.116,0.037,2.841-2.105,5.024c-2.127,2.178-5.973,3.322-11.128,3.322c-3.024,0-5.22-0.87-6.888-2.761
		c-3.57-4.021-4.146-11.645-4.011-16.348c20.115-5.863,34.876-24.439,34.876-46.43V89.245h8.159c2.977,0,5.376-2.402,5.376-5.375
		C291.521,80.904,289.116,78.5,286.146,78.5z M267.231,136.716c0,20.746-16.888,37.627-37.629,37.627H59.371
		c-20.749,0-37.628-16.881-37.628-37.627V89.25h245.489V136.716z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconBathtub",
};
</script>

<style>
.custom-icon {
  fill: currentColor;
}
</style>
