import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

import { icons } from "@/static/icons";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: icons,
  },
  theme: {
    dark: false,
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#000",
        error: "#CF423C",
        success: "#128A0B",
        info: "#0099FF",
        warning: "#FDB929",
      },
      dark: {
        secondary: "#363839",
      },
    },
  },
});
