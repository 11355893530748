<template>
  <svg viewBox="0 0 105 105" class="custom-icon">
    <g>
      <path
        d="M11.7,33c0.7,0.5,1.7,0.4,2.4-0.2c1.1-1,3.2-1.6,5.5-1.6c1.9,0,3.8,0.5,5,1.2c1.6,1.1,3.6,1.1,5.2,0c1.2-0.8,3-1.2,5-1.2     s3.8,0.5,5,1.2c1.6,1.1,3.6,1.1,5.2,0c0.7-0.4,1.5-0.7,2.5-1v26.9h5V31.5c1,0.2,1.8,0.5,2.5,1c1.6,1.1,3.6,1.1,5.2,0     c1.2-0.8,3-1.2,5-1.2c1.9,0,3.8,0.5,5,1.2c1.6,1.1,3.6,1.1,5.2,0c1.2-0.8,3-1.2,5-1.2c2.2,0,4.4,0.6,5.5,1.6     c0.4,0.3,0.8,0.5,1.3,0.5c0.4,0,0.7-0.1,1-0.3c0.8-0.5,1.2-1.5,0.9-2.5c-3-10.2-18.6-21.4-36.7-22.4V5c0-1.4-1.1-2.5-2.5-2.5     c-1.4,0-2.5,1.1-2.5,2.5v3.1c-18.1,1-33.6,12.2-36.7,22.4C10.5,31.5,10.9,32.5,11.7,33z"
      />
      <path
        d="M32.2,78.8h0.7c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5h-13c-1.7,0-3.1-1.2-3.4-2.8l-3.3-19.1c-0.2-1.4-1.5-2.3-2.9-2     c-1.4,0.2-2.3,1.5-2,2.9l3.3,19.1c0.5,2.6,2.1,4.7,4.2,5.9l-3.4,16.7c-0.3,1.4,0.6,2.7,2,3c0.2,0,0.3,0.1,0.5,0.1     c1.2,0,2.2-0.8,2.5-2l3.4-16.6h6.4l3.4,16.6c0.2,1.2,1.3,2,2.5,2c0.2,0,0.3,0,0.5-0.1c1.4-0.3,2.2-1.6,2-3L32.2,78.8z"
      />
      <path
        d="M89.7,49.8c-1.4-0.2-2.7,0.7-2.9,2L83.5,71c-0.3,1.6-1.7,2.8-3.4,2.8h-13c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5h0.7     l-3.2,15.6c-0.3,1.4,0.6,2.7,2,3c0.2,0,0.3,0.1,0.5,0.1c1.2,0,2.2-0.8,2.5-2l3.4-16.6h6.4l3.4,16.6c0.2,1.2,1.3,2,2.5,2     c0.2,0,0.3,0,0.5-0.1c1.4-0.3,2.2-1.6,2-3l-3.4-16.7c2.2-1.2,3.8-3.3,4.2-5.9l3.3-19.1C92,51.4,91.1,50.1,89.7,49.8z"
      />
      <path
        d="M56.5,92.5h-4V67.2h14.9c1.4,0,2.5-1.1,2.5-2.5c0-1.4-1.1-2.5-2.5-2.5H32.6c-1.4,0-2.5,1.1-2.5,2.5     c0,1.4,1.1,2.5,2.5,2.5h14.9v25.2h-4c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5h13c1.4,0,2.5-1.1,2.5-2.5S57.9,92.5,56.5,92.5z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconTerrace",
};
</script>

<style>
.custom-icon {
  fill: currentColor;
}
</style>
