<template>
  <svg
    width="512pt"
    height="512pt"
    viewBox="0 0 512 512"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0,512) scale(0.1,-0.1)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M841 4144 c-169 -45 -301 -180 -346 -351 -10 -38 -15 -106 -15 -205 l0 -148 -78 0 c-115 0 -174 -21 -238 -84 -89 -89 -84 -36 -84 -996 0 -959 -4 -907 83 -994 56 -56 126 -86 198 -86 l39 0 0 -135 c0 -131 1 -137 25 -160 23 -24 29 -25 168 -25 123 0 148 3 166 17 15 13 34 61 61 158 22 77 40 141 40 142 0 2 765 3 1700 3 935 0 1700 -1 1700 -3 0 -1 18 -65 40 -142 27 -97 46 -145 61 -157 18 -15 43 -18 166 -18 139 0 145 1 168 25 24 23 25 29 25 158 l0 134 58 5 c103 10 184 65 233 158 l24 45 3 855 c3 982 8 925 -84 1017 -62 62 -121 83 -236 83 l-78 0 0 139 c0 76 -5 162 -10 192 -34 177 -177 327 -355 374 -87 22 -3350 22 -3434 -1z m3467 -183 c70 -38 101 -70 140 -145 25 -48 26 -61 30 -213 l4 -163 -79 0 c-116 0 -175 -21 -239 -84 -77 -78 -84 -109 -84 -377 l0 -218 -50 34 c-27 18 -76 44 -107 57 l-58 23 -1305 0 -1305 0 -58 -23 c-31 -13 -80 -39 -107 -57 l-50 -34 0 219 c0 265 -8 298 -86 377 -62 62 -121 83 -237 83 l-79 0 4 163 c4 152 5 165 30 213 15 28 38 64 52 81 27 32 113 82 160 94 17 4 780 6 1696 6 l1665 -2 63 -34z m-3466 -719 l33 -32 5 -546 5 -546 24 -19 c23 -19 63 -19 1651 -19 1588 0 1628 0 1651 19 l24 19 5 546 5 546 33 32 32 33 250 0 250 0 32 -33 33 -32 0 -850 0 -850 -33 -32 -32 -33 -2250 0 -2250 0 -32 33 -33 32 0 849 0 849 25 27 c14 15 34 31 45 36 11 4 127 7 257 6 l238 -2 32 -33z m3066 -561 c70 -38 101 -70 139 -145 23 -45 27 -66 31 -173 l4 -123 -1522 0 -1522 0 4 123 c5 133 19 177 77 248 31 37 114 87 165 100 17 4 600 6 1296 6 l1265 -2 63 -34z m-3223 -1428 c-3 -16 -13 -52 -21 -80 l-15 -53 -45 0 -44 0 0 80 0 80 66 0 66 0 -7 -27z m3875 -53 l0 -80 -44 0 -45 0 -15 53 c-8 28 -18 64 -21 80 l-7 27 66 0 66 0 0 -80z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconSofaBed",
};
</script>

<style>
.custom-icon {
  fill: currentColor;
}
</style>
