import iconBathtub from "@/customIcons/iconBathtub.vue";
import iconTwinBeds from "@/customIcons/iconTwinBeds.vue";
import iconSharedBathroom from "@/customIcons/iconSharedBathroom.vue";
import iconSofaBed from "@/customIcons/iconSofaBed.vue";
import iconMan from "@/customIcons/iconMan.vue";
import iconHottub from "@/customIcons/iconHottub.vue";
import IconTerrace from "@/customIcons/iconTerrace.vue";

const icons = {
  add_circle: "mdi-plus-circle",
  airco: "mdi-air-conditioner",
  alert: "mdi-alert",
  alert_circle: "mdi-alert-circle",
  arrow_down: "mdi-chevron-down",
  arrow_down_circle: "mdi-chevron-down-circle",
  arrow_left: "mdi-chevron-left",
  arrow_right: "mdi-chevron-right",
  arrow_up: "mdi-chevron-up",
  arrow_up_circle: "mdi-chevron-up-circle",
  balcony: "mdi-balcony",
  bath_shower: "mdi-shower",
  bathtub: {
    component: iconBathtub,
  },
  bunk_beds: "mdi-bunk-bed",
  checkmark: "mdi-check-bold",
  close: "mdi-close",
  delete: "mdi-trash-can",
  double_bed: "mdi-bed-queen",
  edit: "mdi-pencil-circle",
  help: "mdi-help-circle",
  hide_password: "mdi-eye-off",
  information: "mdi-information",
  jacuzzi: {
    component: iconHottub,
  },
  king_bed: "mdi-bed-king",
  language: "mdi-web",
  link_arrow_left: "mdi-arrow-left",
  link_arrow_right: "mdi-arrow-right",
  lits_jumeaux: "mdi-bed-double",
  login: "mdi-login",
  logout: "mdi-logout",
  man: {
    component: iconMan,
  },
  no_pets: "mdi-paw-off",
  password: "mdi-lock",
  pets: "mdi-paw",
  plus: "mdi-plus-box",
  queen_bed: "mdi-bed-queen",
  remove: "mdi-close-circle-outline",
  remove_circle: "mdi-minus-circle",
  remove_outline: "mdi-minus-circle-outline",
  search: "mdi-magnify",
  self_catering: "mdi-stove",
  settings: "mdi-cog",
  shared_bathroom: {
    component: iconSharedBathroom,
  },
  show_password: "mdi-eye",
  shower: "mdi-shower-head",
  single_bed: "mdi-bed-single",
  snackbar_error: "mdi-alert-circle-outline",
  snackbar_info: "mdi-alert-circle-outline",
  snackbar_success: "mdi-check-circle-outline",
  sofa_beds: {
    component: iconSofaBed,
  },
  tea_coffee: "mdi-coffee",
  terrace: {
    component: IconTerrace,
  },
  trips: "mdi-bag-suitcase",
  tv: "mdi-television",
  twin_beds: {
    component: iconTwinBeds,
  },
  username: "mdi-account",
  wheelchair: "mdi-human-wheelchair",
  wifi: "mdi-wifi",
};

export { icons };
