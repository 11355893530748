<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width="399.000000pt"
    height="253.000000pt"
    viewBox="0 0 399.000000 253.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,253.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M1790 1265 l0 -1265 95 0 95 0 0 1265 0 1265 -95 0 -95 0 0 -1265z"
      />
      <path
        d="M2582 2433 c-22 -9 -42 -61 -42 -113 l0 -50 653 2 652 3 3 48 c3 43 0 51 -31 82 l-35 35 -594 -1 c-326 0 -599 -3 -606 -6z"
      />
      <path
        d="M2730 2160 c-11 -11 -20 -24 -20 -30 0 -15 38 -50 55 -50 8 0 22 11 31 25 15 23 15 27 0 50 -20 30 -39 32 -66 5z"
      />
      <path
        d="M2945 2160 c-36 -40 7 -96 55 -70 24 13 26 52 4 74 -21 21 -37 20 -59 -4z"
      />
      <path
        d="M3150 2160 c-25 -25 -25 -32 -2 -60 25 -31 69 -21 78 17 5 20 2 31 -16 45 -28 23 -35 23 -60 -2z"
      />
      <path
        d="M3358 2169 c-10 -6 -18 -22 -18 -37 0 -50 42 -69 73 -34 23 25 21 48 -5 66 -26 19 -27 19 -50 5z"
      />
      <path
        d="M3557 2162 c-22 -24 -21 -45 1 -65 24 -22 38 -21 62 3 21 21 18 46 -8 68 -22 17 -36 15 -55 -6z"
      />
      <path
        d="M2645 1950 c-15 -17 -16 -24 -7 -46 10 -21 18 -25 45 -22 27 2 33 8 35 31 6 53 -38 75 -73 37z"
      />
      <path
        d="M3635 1946 c-23 -34 -5 -66 35 -66 40 0 58 32 35 66 -8 13 -24 24 -35 24 -11 0 -27 -11 -35 -24z"
      />
      <path
        d="M2911 1944 c-33 -23 -26 -68 12 -72 54 -6 76 45 32 74 -18 12 -24 11 -44 -2z"
      />
      <path
        d="M3400 1942 c-18 -15 -22 -24 -16 -46 6 -24 11 -27 44 -24 33 3 37 6 37 32 0 18 -8 34 -21 43 -19 12 -24 11 -44 -5z"
      />
      <path
        d="M3146 1928 c-13 -19 -14 -25 -2 -43 16 -25 65 -27 79 -3 8 12 8 24 -1 42 -15 33 -55 35 -76 4z"
      />
      <path
        d="M2566 1744 c-30 -30 -13 -74 29 -74 27 0 45 18 45 45 0 42 -44 59 -74 29z"
      />
      <path
        d="M3715 1736 c-24 -36 -5 -68 38 -64 28 3 32 7 35 37 5 53 -44 71 -73 27z"
      />
      <path
        d="M2850 1711 c-19 -37 19 -79 60 -66 25 8 37 50 20 70 -18 22 -67 19 -80 -4z"
      />
      <path
        d="M3432 1718 c-36 -36 7 -91 55 -69 20 9 24 17 21 44 -3 27 -7 32 -33 35 -17 2 -36 -3 -43 -10z"
      />
      <path
        d="M2482 1538 c-7 -7 -12 -21 -12 -33 0 -48 59 -62 86 -20 16 24 16 27 -1 45 -20 22 -55 26 -73 8z"
      />
      <path
        d="M3802 1538 c-30 -30 -3 -83 39 -76 32 5 45 31 31 62 -13 28 -49 35 -70 14z"
      />
      <path
        d="M560 1483 c-131 -20 -208 -103 -218 -234 -9 -130 57 -285 148 -345 53 -35 124 -43 175 -18 130 62 216 274 171 426 -32 110 -158 188 -276 171z"
      />
      <path
        d="M3129 1479 c-86 -13 -155 -68 -185 -145 -25 -66 -16 -212 18 -286 54 -117 150 -187 240 -175 192 26 304 387 165 535 -52 56 -149 85 -238 71z"
      />
      <path
        d="M2402 1328 c-17 -17 -15 -53 3 -68 38 -31 96 27 65 65 -15 18 -51 20 -68 3z"
      />
      <path
        d="M3882 1328 c-28 -28 -7 -78 33 -78 27 0 45 18 45 45 0 27 -18 45 -45 45 -12 0 -26 -5 -33 -12z"
      />
      <path
        d="M310 730 c-120 -25 -196 -56 -219 -89 -20 -27 -30 -127 -58 -543 l-6 -98 566 0 567 0 0 38 c0 53 -30 511 -36 549 -11 74 -75 111 -258 147 -113 23 -440 21 -556 -4z"
      />
      <path
        d="M2865 723 c-209 -47 -205 -40 -228 -393 -10 -151 -21 -287 -23 -302 l-6 -28 568 0 567 0 -7 142 c-4 79 -13 218 -20 310 -11 155 -14 170 -37 194 -36 39 -70 53 -187 80 -95 21 -130 24 -312 23 -182 0 -218 -3 -315 -26z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconSharedBathroom",
};
</script>

<style>
.custom-icon {
  fill: currentColor;
}
</style>
