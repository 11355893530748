<template>
  <v-snackbar
    v-model="show"
    top
    class="pt-0"
    :color="options.color"
    content-class="d-flex"
    :class="{ 'icon-message': options.icon }"
  >
    <v-icon
      v-if="options.icon"
      color="white"
      left
      class="align-self-start"
      v-text="'' + options.icon"
    ></v-icon>
    <span>{{ message }}</span>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    show: false,
    message: null,
    options: {
      color: "",
      icon: "",
    },
  }),
  methods: {
    open(message, options) {
      this.show = true;
      this.message = message;
      this.options = Object.assign(this.options, options);
    },
  },
};
</script>
