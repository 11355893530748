import Vue from "vue";
import Vuex from "vuex";

import languageModule from "./modules/appLanguage";
import stylesModule from "./modules/appStyles";
import userModule from "./modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    hotel: null,
    roomsGuests: [],
    roomToSelect: null,
    step: 0,
    roomBookings: [],
    result: null,
    promoCode: "",
    preAuth: false,
    stayDates: null,
    nights: 0,
    additionalServices: [],
    segment: "",
    packageData: {
      rateCode: "",
      roomCode: "",
      rateData: null,
      image: null,
      maxLos: 0,
    },
    resId: "",
    noImageCache: "",
    total: 0,
    dueNow: 0,
  },
  modules: {
    language: languageModule,
    user: userModule,
    appStyles: stylesModule,
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token;
    },
    setHotel: (state, hotel) => {
      state.hotel = hotel;
    },
    setRoomsGuests: (state, rooms) => {
      state.roomsGuests = rooms;
    },
    setRoomToSelect: (state, roomToSelect) => {
      state.roomToSelect = roomToSelect;
    },
    setStep: (state, step) => {
      state.step = step;
    },
    setRoomBookings: (state, bookings) => {
      state.roomBookings = bookings;
    },
    setResult: (state, result) => {
      state.result = result;
    },
    setPromoCode: (state, code) => {
      state.promoCode = code;
    },
    setPreAuth: (state, preauth) => {
      state.preAuth = preauth;
    },
    setStayDates: (state, dates) => {
      state.stayDates = dates;
    },
    setNights: (state, nights) => {
      state.nights = nights;
    },
    setAdditionalServices: (state, services) => {
      state.additionalServices = services;
    },
    setSegment: (state, segment) => {
      state.segment = segment;
    },
    setPackageData: (state, packageData) => {
      state.packageData = packageData;
    },
    setResId: (state, resId) => {
      state.resId = resId;
    },
    setNoImageCache: (state, str) => {
      state.noImageCache = str;
    },
    setTotal: (state, total) => {
      state.total = total;
    },
    setDueNow: (state, dueNow) => {
      state.dueNow = dueNow;
    },
  },
  actions: {
    setToken: (context, token) => {
      context.commit("setToken", token);
    },
    setHotel: (context, hotel) => {
      context.commit("setHotel", hotel);
    },
    setRoomsGuests: (context, rooms) => {
      context.commit("setRoomsGuests", rooms);
    },
    setStep: (context, step) => {
      context.commit("setStep", step);
    },
    setRoomBookings: (context, bookings) => {
      context.commit("setRoomBookings", bookings);
    },
    setResult: (context, result) => {
      context.commit("setResult", result);
    },
    setPromoCode: (context, code) => {
      context.commit("setPromoCode", code);
    },
    setPreAuth: (context, preauth) => {
      context.commit("setPreAuth", preauth);
    },
    setStayDates: (context, dates) => {
      context.commit("setStayDates", dates);
    },
    setNights: (context, nights) => {
      context.commit("setNights", nights);
    },
    setAdditionalServices: (context, services) => {
      context.commit("setAdditionalServices", services);
    },
    setSegment: (context, segment) => {
      context.commit("setSegment", segment);
    },
    setPackageData: (context, packageData) => {
      context.commit("setPackageData", packageData);
    },
    setResId: (context, resId) => {
      context.commit("setResId", resId);
    },
    setNoImageCache: (context, str) => {
      context.commit("setNoImageCache", str);
    },
    setTotal: (context, total) => {
      context.commit("setTotal", total);
    },
    setDueNow: (context, dueNow) => {
      context.commit("setDueNow", dueNow);
    },
    setRoomToSelect: (context, roomToSelect) => {
      context.commit("setRoomToSelect", roomToSelect);
    },
  },
});
