const routes = [
  {
    path: "/",
    name: "landing",
    meta: { layout: "app-layout" },
    component: () =>
      import(/* webpackChunkName: "landingpage" */ "@/views/LandingPage.vue"),
  },
  {
    path: "/chain",
    name: "chainpage",
    meta: { layout: "app-layout" },
    component: () =>
      import(
        /* webpackChunkName: "landingpage" */ "@/views/ChainLandingPage.vue"
      ),
  },
  {
    path: "/bookings",
    name: "bookings",
    meta: { layout: "app-layout" },
    component: () =>
      import(
        /* webpackChunkName: "landingpage" */ "@/views/BookingsOverview.vue"
      ),
  },
  // {
  //   path: "/icons",
  //   name: "icons",
  //   meta: { requiresAuth: false, layout: "simple-layout" },
  //   component: () =>
  //     import(/* webpackChunkName: "404" */ "@/views/UsedIcons.vue"),
  // },
  {
    path: "*",
    name: "404",
    meta: { layout: "simple-layout" },
    component: () =>
      import(/* webpackChunkName: "404" */ "@/views/PageNotFound.vue"),
  },
];

export default routes;
