<template>
  <svg
    viewBox="0 0 640.000000 1280.000000"
    class="custom-icon"
    style="enable-background: new 0 0 22 22"
  >
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
      <path
        d="M3027 12784 c-290 -52 -544 -220 -705 -463 -134 -204 -189 -425 -170
-681 30 -386 296 -743 659 -886 143 -56 212 -68 389 -69 168 0 209 6 340 47
263 83 515 309 630 562 124 273 129 581 13 856 -73 174 -231 368 -378 465
-233 154 -520 216 -778 169z"
      />
      <path
        d="M1920 10435 c-8 -2 -49 -9 -90 -15 -106 -17 -265 -71 -371 -126 -394
-204 -653 -566 -731 -1024 -10 -59 -13 -445 -13 -1815 l0 -1740 22 -71 c71
-223 311 -355 546 -300 161 38 267 129 328 281 l24 60 3 1553 2 1552 110 0
110 0 2 -4152 3 -4153 21 -61 c59 -169 154 -284 295 -353 190 -93 392 -93 586
0 152 73 269 220 314 394 10 40 14 536 16 2472 l3 2423 105 0 105 0 0 -2407
c0 -2080 2 -2418 15 -2478 61 -293 341 -494 655 -471 260 18 457 165 538 401
l27 80 3 4153 2 4153 108 -3 107 -3 5 -1555 c4 -1101 8 -1564 16 -1585 75
-204 232 -315 447 -315 234 0 413 158 447 395 8 58 10 541 8 1770 -3 1588 -5
1696 -22 1785 -110 572 -500 992 -1046 1128 l-105 26 -1290 2 c-709 1 -1297 1
-1305 -1z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconMan",
};
</script>

<style>
.custom-icon {
  fill: currentColor;
}
</style>
