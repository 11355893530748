<template>
  <v-dialog id="loader-dialog" v-model="dialog" persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        {{ message }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      message: null,
    };
  },
  methods: {
    open(message) {
      this.dialog = true;
      this.message = message;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
