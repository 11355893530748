<template>
  <svg
    x="0px"
    y="0px"
    viewBox="166.77 270.95 418.48 210.09"
    class="custom-icon"
    style="enable-background: new 0 0 22 22"
  >
    <g>
      <path
        d="m359.64 353.61v-74.051c0-4.7344-3.875-8.6094-8.6094-8.6094h-165.32c-4.7344 0-8.6094 3.875-8.6094 8.6094v74.051c-6.0273 4.7344-10.332 12.055-10.332 20.234v98.59c0 4.7344 3.875 8.6094 8.6094 8.6094h22.387c4.7344 0 8.6094-3.875 8.6094-8.6094v-21.098h123.99v21.098c0 4.7344 3.875 8.6094 8.6094 8.6094h22.387c4.7344 0 8.6094-3.875 8.6094-8.6094v-29.707l0.003906-48.215v-20.664c0-8.1836-4.3047-15.5-10.332-20.238zm-175.66 20.234c0-4.3047 3.4453-7.75 7.75-7.75h153.27c4.3047 0 7.75 3.4453 7.75 7.75v12.055h-168.77zm10.336-85.676h148.1v60.703l-9.4727 0.003906v-9.4727c0-15.5-12.484-27.555-27.555-27.555h-73.191c-15.5 0-27.555 12.484-27.555 27.555v9.4727h-9.4727l0.003907-60.707z"
      />
      <path
        d="m574.91 353.61v-74.051c0-4.7344-3.875-8.6094-8.6094-8.6094h-165.32c-4.7344 0-8.6094 3.875-8.6094 8.6094v74.051c-6.0273 4.7344-10.332 12.055-10.332 20.234v98.59c0 4.7344 3.875 8.6094 8.6094 8.6094h22.387c4.7344 0 8.6094-3.875 8.6094-8.6094v-21.098h123.99v21.098c0 4.7344 3.875 8.6094 8.6094 8.6094h22.387c4.7344 0 8.6094-3.875 8.6094-8.6094v-29.707l0.003906-48.215v-20.664c0-8.1836-4.3047-15.5-10.332-20.238zm-175.66 20.234c0-4.3047 3.4453-7.75 7.75-7.75h153.27c4.3047 0 7.75 3.4453 7.75 7.75v12.055h-168.77zm10.332-85.676h148.1v60.703l-9.4727 0.003906v-9.4727c0-15.5-12.484-27.555-27.555-27.555h-73.191c-15.5 0-27.555 12.484-27.555 27.555v9.4727h-9.4727l0.007812-60.707z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconTwinBeds",
};
</script>

<style>
.custom-icon {
  fill: currentColor;
}
</style>
