const translations = {
  "nl-NL": {
    "1adult": "1 volwassene",
    "1child": "1 kind",
    "1infant": "1 kind/baby",
    "1person": "1 persoon",
    "404message": "De pagina {0} bestaat niet.",
    add: "Toevoegen",
    additionalServices: "Verplichte toeslagen",
    address: "Adres",
    addRoom: "Kamer toevoegen",
    adult: "Volwassene",
    adults: "Volwassenen",
    agreeTo: "Ik ga akkoord met de",
    airconditioning: "Airconditioning",
    all: "Alle",
    allRoomsSelected: "U heeft uw kamer(s) al geselecteerd.",
    amount: "Bedrag",
    apartment: "Appartement",
    arrival: "Aankomst",
    arrivalDate: "Aankomstdatum",
    authorisedResultHeader: "Bedankt!",
    available: "Beschikbaar",
    back: "Terug",
    backToPrevious: "Terug naar vorige pagina",
    balcony: "Balkon",
    bath: "Bad",
    bathShower: "Bad & douche",
    book: "Boeken",
    bookerIsGuestLabel: "Ik ben een gast in deze kamer",
    bookingCreatedSuccessfully: "Het aanmaken van de boeking is gelukt",
    bookingSummary: "Boekingsoverzicht",
    bunkBeds: "Stapelbedden",
    businessBookingLabel: "Zakelijke boeking",
    cancel: "Annuleren",
    cancelBooking: "Boeking annuleren",
    cancelBookingButton: "Annuleren",
    cancelBookingConfirmMessage:
      "Selecteer 'Doorgaan' om de deze booking te annuleren.",
    cancelBookingFailedMessage: "Het annuleren van de boeking is mislukt",
    cancelBookingSuccessMessage: "De boeking is succesvol geannuleerd.",
    cancelledAlert: "De transactie is geannuleerd door uw bank",
    cancelledBookings: "Geannuleerde boekingen",
    cancelledResultHeader: "Uw betaling is geannuleerd",
    checkConfirmBooking: "Bevestigen",
    child: "Kind",
    children: "Kinderen",
    childrenAges: "Kinderen leeftijd",
    city: "Stad",
    clear: "Wissen",
    clearSelection: "Geselecteerde kamer(s) verwijderen",
    clearSelectionConfirmMessage:
      "Selecteer 'Doorgaan' om de geselecteerde kamer(s) te verwijderen.",
    close: "Sluiten",
    code: "Code",
    companyName: "Bedrijfsnaam",
    confirm: "Bevestigen",
    continue: "Doorgaan",
    cottage: "Vakantiehuis",
    country: "Land",
    createNewBooking: "Nieuwe boeking maken",
    creatingBookingFailed: "Aanmaken boeking mislukt",
    defaultRefused:
      "Uw betaling is geweigerd. Controleer of u het formulier correct heeft ingevuld en probeer het opnieuw.",
    departure: "Vertrek",
    depositRequired: "Aanbetaling vereist",
    disabledFacilities: "Voorzieningen voor gehandicapten",
    done: "Klaar",
    doubleBed: "Tweepersoonsbed",
    dueNow: "Vooruitbetaling",
    email: "E-mail",
    emailAddress: "E-mailadres",
    estimatedTimeArrival: "Aankomsttijd",
    extraLargeBed: "Extra groot bed",
    firstName: "Voornaam",
    flexibleCancellation: "Flexibel annuleerbaar",
    freeCancellation: "Gratis annuleerbaar",
    from: "vanaf",
    fullyPrepaid: "Volledig vooruitbetaald",
    garanteeNow: "Nu garanderen",
    guaranteedByCc: "Gegarandeerd met creditcard",
    guest: "Gast",
    guestDetails: "Gastdetails",
    guests: "Gasten",
    hotel: "Hotel",
    hotelRoom: "Hotelkamer",
    infant: "Kind",
    infants: "Kinderen",
    invalid: "Ongeldig",
    invalidDateRange: "Kies a.u.b. uw aankomst- en vertrekdatum",
    invalidEmailError: "Het ingevoerde e-mailadres is niet valide",
    invalidFormInputsMessage:
      "Het formulier bevat ongeldige of ontbrekende waarden",
    invalidPhoneNumber: "Het nummer moet met + of 0 beginnen",
    invalidValue: "Ongeldige waarde",
    jacuzzi: "Jacuzzi",
    kingSizeBed: "King size bed",
    login: "Inloggen",
    loginErrorMessage:
      "Het inloggen is mislukt. De ingevoerde combinatie van e-mailadres en pincode is onjuist.",
    logout: "Uitloggen",
    loadingDataFailed: "Het laden van de data is mislukt.",
    name: "Naam",
    nextRoom: "Volgende kamer",
    night: "nacht",
    nights: "Nachten",
    nights_plur: "nachten",
    noAvailableRoomsFound: "Geen beschikbare kamers gevonden",
    noBookingsFound: "Geen boekingen gevonden.",
    noDataAvailable: "Geen data beschikbaar",
    noDataFound: "Geen data gevonden",
    noDataFound: "Geen data gevonden",
    noPackagesFound: "Geen arrangementen gevonden",
    noPaymentMethodsAvailable: "Geen betaalmethodes beschikbaar.",
    noPaymentMethodsAvailable: "Geen betaalmethodes beschikbaar.",
    noPetsAllowed: "Geen huisdieren toegestaan",
    noRoomsFound: "Geen kamers gevonden",
    notAvailable: "Niet beschikbaar",
    noUpComingVisitsFound: "Geen komende bezoeken gevonden.",
    numberOfAdults: "{0} volwassenen",
    numberOfChildren: "{0} kinderen",
    numberOfInfants: "{0} kind/baby",
    numberOfPersons: "{0} personen",
    options: "Extra's",
    packages: "Arrangementen",
    pageNotFound: "Pagina niet gevonden",
    pay: "Betalen",
    payInHotel: "Betalen in hotel",
    payInHotelDescription:
      "U betaalt bij aankomst bij de receptie van het hotel.",
    payLater: "Betaal later",
    payLaterDescription:
      "U betaalt nog niet, we hebben uw creditcardgegevens alleen nodig om uw reservering te garanderen.",
    payment: "Betaling",
    payMentDetails: "Betalingsdetails",
    paymentGuarantee: "Betalingsgarantie",
    payNow: "Betaal nu",
    payNowDescription: "U betaalt direct na het afronden van uw reservering.",
    payOptionsTitle: "Wanneer wilt u betalen?",
    pendingAlert:
      "We hebben je reservering ontvangen en wachten op de betaling.",
    pendingResultHeader: "Uw betaling is in behandeling",
    perAdultPerNight: "Per volwassene per nacht",
    perAdultPerStay: "Per volwassene per verblijf",
    percentagePerStay: "Percentage per verblijf",
    percentagePerStayPerAdult: "Percentage per verblijf per volwassene",
    perChildPerNight: "Per kind per nacht",
    perChildPerStay: "Per kind per verblijf",
    perInfantPerNight: "Per kind/baby per nacht",
    perInfantPerStay: "Per kind/baby per verblijf",
    perNight: "Per nacht",
    perPersonPerNight: "Per persoon per nacht",
    perPersonPerStay: "Per persoon per verblijf",
    person: "Persoon",
    persons: "Personen",
    perStay: "Per verblijf",
    perUnit: "Per stuk",
    perUnitPerAdult: "Per stuk per volwassene",
    perUnitPerAdultPerNight: "Per stuk per volwassene per nacht",
    perUnitPerNight: "Per stuk per nacht",
    petsAllowed: "Huisdieren toegestaan",
    phoneNumber: "Telefoonnummer",
    postalCode: "Postcode",
    privacyPolicy: "het privacybeleid",
    processingBooking: "Boeking verwerken",
    promoCode: "Promotie-/bedrijfs-/kortingscode",
    queenSizeBed: "Queen size bed",
    rate: "Tarief",
    readLess: "Inklappen",
    readMore: "Lees meer",
    reason: "Reden",
    refusalReason_2: "Geweigerd",
    refusalReason_3: "Doorverwijzing",
    refusalReason_4: "Fout van de werver",
    refusalReason_5: "Geblokkeerde kaart",
    refusalReason_6: "Verlopen kaart",
    refusalReason_7: "Ongeldig bedrag",
    refusalReason_8: "Ongeldig kaartnummer",
    refusalReason_9: "Uitgevende instelling niet beschikbaar",
    refusalReason_10: "Niet ondersteund",
    refusalReason_11: "3D Niet geauthenticeerd",
    refusalReason_12: "Niet genoeg saldo",
    refusalReason_14: "Fraude met verwerver",
    refusalReason_15: "Geannuleerd",
    refusalReason_16: "Shopper Geannuleerd",
    refusalReason_17: "Ongeldige pin",
    refusalReason_18: "Pin pogingen overschreden",
    refusalReason_19: "Pin validatie niet mogelijk",
    refusalReason_20: "FRAUDE",
    refusalReason_21: "Niet Ingediend",
    refusalReason_22: "FRAUDE-GEANNULEERD",
    refusalReason_23: "Transactie niet toegestaan",
    refusalReason_24: "CVC geweigerd",
    refusalReason_25: "Kaart met beperkingen",
    refusalReason_26: "Intrekking van bevoegdheid",
    refusalReason_27: "Geweigerd Niet Algemeen",
    refusalReason_28: "Opgenomen bedrag overschreden",
    refusalReason_29: "Aantal opnames overschreden",
    refusalReason_31: "Emittent verdacht van fraude",
    refusalReason_32: "AVS Afgewezen",
    refusalReason_33: "Kaart vereist online pin",
    refusalReason_34: "Geen betaalrekening beschikbaar op de kaart",
    refusalReason_35: "Geen spaarrekening beschikbaar op de kaart",
    refusalReason_36: "Mobiele PIN vereist",
    refusalReason_37: "Contactloze fallback",
    refusalReason_38: "Authenticatie vereist",
    refusalReason_39: "RReq niet ontvangen van DS",
    refusedAlert: "De betaling is geweigerd door uw bank",
    refusedResultHeader: "Uw betaling is geweigerd",
    removeOption: "Extra verwijderen",
    removeOptionConfirmMessage: "Selecteer 'Doorgaan' om '{0}' te verwijderen.",
    removeRoom: "Kamer verwijderen",
    removeRoomBookingConfirmMessage:
      "Selecteer 'Doorgaan' om deze kamer te verwijderen",
    removeRoomsGuestsRoomConfirmMessage:
      "Selecteer 'Doorgaan' om deze kamer te verwijderen",
    requiredFieldError: "Dit veld is verplicht.",
    reservation: "Reservering",
    retrieveDataErrorMessage:
      "Het ophalen van de gegevens voor uw betaling is mislukt.",
    retrieveDataErrorTitle: "Het ophalen van de data is mislukt.",
    room: "Kamer",
    rooms: "Kamers",
    roomTypeFilter: "Kamertype filter",
    search: "Zoeken",
    searchCriteria: "Zoekcriteria",
    selectedDatesPerAdult: "Per volwassene",
    selectedDatesPerChild: "Per kind",
    selectedDatesPerInfant: "Per baby",
    selectedDatesPerPerson: "Per persoon",
    selectedDatesPerStay: "Per stuk",
    selectOptionsTitle: "Extra's",
    selectPackage: "Kies een arrangement",
    selectPackageWarning:
      "Als u dit arrangement kiest, wordt uw verblijfsduur gewijzigd naar {0} nachten.",
    selectYourHotel: "Kies uw hotel",
    selectYourRoom: "Kies uw kamer",
    selfCatering: "Zelfbediening",
    sharedBathroom: "Gedeelde badkamer",
    shower: "Douche",
    showRoomImages: "Meer kamerafbeeldingen",
    showPackageImages: "Meer arrangementafbeeldingen",
    singleBed: "Eenpersoonsbed",
    sofaBed: "Slaapbank",
    specialRequests: "Verzoeken",
    stayDates: "Verblijfdata",
    stayDatesLabel: "Aankomst ~ vertrek",
    studio: "Studio",
    suite: "Suite",
    surName: "Achternaam",
    teaCoffee: "Thee en koffie",
    termsConditions: "algemene voorwaarden",
    terrace: "Terras",
    thankYouP1:
      "Bedankt voor uw boeking! U ontvangt binnenkort uw bevestiging per e-mail.",
    thankYouP2:
      "Neem a.u.b. contact met ons op als u vragen heeft of als we u verder kunnen helpen.",
    thankYouP3: "Met vriendelijke groet,",
    thankYouTitle: "Bedankt",
    time: "Tijd",
    today: "Vandaag",
    toOverview: "Naar overzicht",
    tv: "TV",
    twinBeds: "Twee eenpersoonsbedden",
    undefined: "Onbekend",
    undefinedError: "Onbekende fout",
    units: "Aantal",
    unknown: "Onbekend",
    upComingVisits: "Komende bezoeken",
    userLoginIntro:
      "Log in met uw e-mail en pincode om reserveringen, die u via deze website hebt aangemaakt, te beheren.",
    viewRates: "tarieven",
    villa: "Villa",
    wifi: "WIFI",
    yourBookings: "Uw boekingen",
    yourDetails: "Uw details",
    yourDiscount: "Uw korting: ",
    yourTotal: "Totaal",
  },
  "en-US": {
    "1adult": "1 adult",
    "1child": "1 child",
    "1infant": "1 infant",
    "1person": "1 person",
    "404message": "The page with url {0} does not exist",
    add: "Add",
    additionalServices: "Additional Fees & Taxes",
    address: "Address",
    addRoom: "Add room",
    adult: "Adult",
    adults: "Adults",
    agreeTo: "I agree to the",
    airconditioning: "Airconditioning",
    all: "All",
    allRoomsSelected: "You already selected your room(s).",
    amount: "Amount",
    apartment: "Apartment",
    arrival: "Arrival",
    arrivalDate: "Arrival date",
    authorisedResultHeader: "Thank you!",
    available: "Available",
    back: "Back",
    backToPrevious: "Back to previous page",
    balcony: "Balcony",
    bath: "Bath",
    bathShower: "Bath & Shower",
    book: "Book",
    bookerIsGuestLabel: "I'm a guest in this room",
    bookingCreatedSuccessfully: "Booking created successfully",
    bookingSummary: "Booking summary",
    bunkBeds: "Bunk Beds",
    businessBookingLabel: "Business booking",
    cancel: "Cancel",
    cancelBooking: "Cancel booking",
    cancelBookingButton: "Cancel",
    cancelBookingConfirmMessage: "Select 'Continue' to cancel this booking.",
    cancelBookingFailedMessage: "Booking cancellation failed.",
    cancelBookingSuccessMessage: "The booking has been cancelled successfully.",
    cancelledAlert: "The transaction was cancelled by your bank.",
    cancelledBookings: "Cancelled bookings",
    cancelledResultHeader: "Your payment was cancelled",
    checkConfirmBooking: "Check and confirm",
    child: "Child",
    children: "Children",
    childrenAges: "Children ages",
    city: "City",
    clear: "Clear",
    clearSelection: "Clear selected room(s)",
    clearSelectionConfirmMessage:
      "Select 'Continue' to remove the selected room(s).",
    close: "Close",
    code: "Code",
    companyName: "Company name",
    confirm: "Confirm",
    continue: "Continue",
    cottage: "Cottage",
    country: "Country",
    createNewBooking: "Create new booking",
    creatingBookingFailed: "Creating booking failed",
    defaultRefused:
      "Your payment was refused. Please check if you entered the form correctly, and try again.",
    departure: "Departure",
    depositRequired: "Deposit required",
    disabledFacilities: "Disabled facilities",
    done: "Done",
    doubleBed: "Double Bed",
    dueNow: "Due now",
    email: "Email",
    emailAddress: "Email address",
    estimatedTimeArrival: "ETA",
    extraLargeBed: "Extra large bed",
    firstName: "First name",
    flexibleCancellation: "Flexible cancellation",
    freeCancellation: "Free cancellation",
    from: "from",
    fullyPrepaid: "Fully prepaid",
    garanteeNow: "Guarantee now",
    guaranteedByCc: "Guaranteed by credit card",
    guest: "Guest",
    guestDetails: "Guest details",
    guests: "Guests",
    hotel: "Hotel",
    hotelRoom: "Hotel room",
    infant: "Infant",
    infants: "Infants",
    invalid: "Invalid",
    invalidDateRange: "Please select arrival and departure date",
    invalidEmailError: "The entered e-mail address is not valid",
    invalidFormInputsMessage: "The form contains invalid or missing values",
    invalidPhoneNumber: "The first character should be either + or 0",
    invalidValue: "Invalid value",
    jacuzzi: "Jacuzzi",
    kingSizeBed: "King size bed",
    login: "Log in",
    loginErrorMessage:
      "Login failed. The combination of email address and PIN entered is incorrect.",
    logout: "Log off",
    loadingDataFailed: "Loading data failed",
    name: "Name",
    nextRoom: "Next room",
    night: "night",
    nights: "Nights",
    nights_plur: "nights",
    noAvailableRoomsFound: "No available rooms found",
    noBookingsFound: "No bookings found.",
    noDataAvailable: "No data available",
    noDataFound: "No data found",
    noPackagesFound: "No packages found",
    noPaymentMethodsAvailable: "No payment methods available.",
    noPaymentMethodsAvailable: "No payment methods available.",
    noPetsAllowed: "Pets are not allowed",
    noRoomsFound: "No rooms found",
    notAvailable: "Not available",
    noUpComingVisitsFound: "No upcoming visits found.",
    numberOfAdults: "{0} adults",
    numberOfChildren: "{0} children",
    numberOfInfants: "{0} infants",
    numberOfPersons: "{0} persons",
    options: "Options",
    packages: "Package deals",
    pageNotFound: "Page not found",
    pay: "Pay",
    payInHotel: "Pay in hotel",
    payInHotelDescription: "You will pay upon arrival at the hotel reception.",
    payLater: "Pay later",
    payLaterDescription:
      "Your card will not be charged, we only require your card data to guarantee your reservation.",
    payment: "Payment",
    payMentDetails: "Payment details",
    paymentGuarantee: "Payment guarantee",
    payNow: "Pay now",
    payNowDescription: "You will pay right after completing your reservation.",
    payOptionsTitle: "When do you want to pay?",
    pendingAlert:
      "We received your reservation and are waiting for the payment to be completed.",
    pendingResultHeader: "Your payment is pending",
    perAdultPerNight: "Per adult per night",
    perAdultPerStay: "Per adult per stay",
    percentagePerStay: "Percentage per stay",
    percentagePerStayPerAdult: "Percentage per stay per adult",
    perChildPerNight: "Per child per night",
    perChildPerStay: "Per child per stay",
    perInfantPerNight: "Per infant per night",
    perInfantPerStay: "Per infant per stay",
    perNight: "Per night",
    perPersonPerNight: "Per person per night",
    perPersonPerStay: "Per person per stay",
    person: "Person",
    persons: "Persons",
    perStay: "Per stay",
    perUnit: "Per unit",
    perUnitPerAdult: "Per unit per adult",
    perUnitPerAdultPerNight: "Per unit per adult per night",
    perUnitPerNight: "Per unit per night",
    petsAllowed: "Pets allowed",
    phoneNumber: "Phone number",
    postalCode: "Postal code",
    privacyPolicy: "privacy policy",
    processingBooking: "Processing booking",
    promoCode: "Promotion/Corporate/Discount Code",
    queenSizeBed: "Queen size bed",
    rate: "Rate",
    readLess: "Read less",
    readMore: "Read more",
    reason: "Reason",
    refusalReason_2: "Refused",
    refusalReason_3: "Referral",
    refusalReason_4: "Acquirer Error",
    refusalReason_5: "Blocked Card",
    refusalReason_6: "Expired Card",
    refusalReason_7: "Invalid Amount",
    refusalReason_8: "Invalid Card Number",
    refusalReason_9: "Issuer Unavailable",
    refusalReason_10: "Not supported",
    refusalReason_11: "3D Not Authenticated",
    refusalReason_12: "Not enough balance",
    refusalReason_14: "Acquirer Fraud",
    refusalReason_15: "Cancelled",
    refusalReason_16: "Shopper Cancelled",
    refusalReason_17: "Invalid Pin",
    refusalReason_18: "Pin tries exceeded",
    refusalReason_19: "Pin validation not possible",
    refusalReason_20: "FRAUD",
    refusalReason_21: "Not Submitted",
    refusalReason_22: "FRAUD-CANCELLED",
    refusalReason_23: "Transaction Not Permitted",
    refusalReason_24: "CVC Declined",
    refusalReason_25: "Restricted Card",
    refusalReason_26: "Revocation Of Auth",
    refusalReason_27: "Declined Non Generic",
    refusalReason_28: "Withdrawal amount exceeded",
    refusalReason_29: "Withdrawal count exceeded",
    refusalReason_31: "Issuer Suspected Fraud",
    refusalReason_32: "AVS Declined",
    refusalReason_33: "Card requires online pin",
    refusalReason_34: "No checking account available on Card",
    refusalReason_35: "No savings account available on Card",
    refusalReason_36: "Mobile PIN required",
    refusalReason_37: "Contactless fallback",
    refusalReason_38: "Authentication required",
    refusalReason_39: "RReq not received from DS",
    refusedAlert: "The transaction was refused by your bank.",
    refusedResultHeader: "Your payment was refused",
    removeOption: "Remove option",
    removeOptionConfirmMessage: "Select 'Continue' to remove '{0}'.",
    removeRoom: "Remove room",
    removeRoomBookingConfirmMessage: "Select 'Continue' to remove this room",
    removeRoomsGuestsRoomConfirmMessage:
      "Select 'Continue' to remove this room",
    requiredFieldError: "This field is required.",
    reservation: "Reservation",
    retrieveDataErrorMessage: "We couldn't retrieve the data for your payment.",
    retrieveDataErrorTitle: "Retrieving data failed",
    room: "Room",
    rooms: "Rooms",
    roomTypeFilter: "Room type filter",
    search: "Search",
    searchCriteria: "Search criteria",
    selectedDatesPerAdult: "Per adult",
    selectedDatesPerChild: "Per child",
    selectedDatesPerInfant: "Per infant",
    selectedDatesPerPerson: "Per person",
    selectedDatesPerStay: "Per unit",
    selectOptionsTitle: "Enhance your stay",
    selectPackage: "Select a package deal",
    selectPackageWarning:
      "Selecting this package will set your stay to {0} nights.",
    selectYourHotel: "Select your hotel",
    selectYourRoom: "Select your room",
    selfCatering: "Self Catering",
    sharedBathroom: "Shared Bathroom",
    shower: "Shower",
    showRoomImages: "Show room images",
    showPackageImages: "Show package images",
    singleBed: "Single bed",
    sofaBed: "Sofa Bed",
    specialRequests: "Special Requests",
    stayDates: "Stay dates",
    stayDatesLabel: "Arrival ~ departure",
    studio: "Studio",
    suite: "Suite",
    surName: "Surname",
    teaCoffee: "Tea & coffee",
    termsConditions: "Terms & Conditions",
    terrace: "Terrace",
    thankYouP1:
      "Thank you for your booking! You will receive your confirmation by email shortly.",
    thankYouP2:
      "Please do not hesitate to contact us if you have any questions or if we can be of any further assistance. We look forward to seeing you soon.",
    thankYouP3: "Regards,",
    thankYouTitle: "Thank you",
    time: "Time",
    today: "Today",
    toOverview: "To overview",
    tv: "TV",
    twinBeds: "Twin Beds",
    undefined: "Undefined",
    undefinedError: "Undefined error",
    units: "Units",
    unknown: "Unknown",
    upComingVisits: "Upcoming visits",
    userLoginIntro:
      "Log in with your e-mail and PIN to manage reservations created through this website.",
    viewRates: "Rates",
    villa: "Villa",
    wifi: "WIFI",
    yourBookings: "Your bookings",
    yourDetails: "Your details",
    yourDiscount: "Your discount: ",
    yourTotal: "Your total",
  },
  "de-DE": {
    "1adult": "1 Erwachsener",
    "1child": "1 Kind",
    "1infant": "1 Kleinkind",
    "1person": "1 Person",
    "404message": "Die Seite mit der Url {0} existiert nicht.",
    add: "Hinzufügen",
    additionalServices: "Pflichtgebühren",
    address: "Adresse",
    addRoom: "Zimmer hinzufügen",
    adult: "Erwachsene",
    adults: "Erwachsene",
    agreeTo: "Ich erkläre mich einverstanden mit",
    airconditioning: "Klimaanlage",
    all: "Alle",
    allRoomsSelected: "Sie haben Ihr(e) Zimmer bereits ausgewählt.",
    amount: "Amount",
    apartment: "Appartement",
    arrival: "Ankunft",
    arrivalDate: "Ankunftsdatum",
    authorisedResultHeader: "Thank you!",
    available: "Verfügbar",
    back: "Zurück",
    backToPrevious: "Back to previous page",
    balcony: "Balkon",
    bath: "Badewanne",
    bathShower: "Badewanne & Dusche",
    book: "Buchen",
    bookerIsGuestLabel: "Ich bin der Gast in diesem Zimmer",
    bookingCreatedSuccessfully: "Buchung erfolgreich erstellt",
    bookingSummary: "Buchungsübersicht",
    bunkBeds: "Etagenbett",
    businessBookingLabel: "Geschäftliche Buchung",
    cancel: "Abbrechen",
    cancelBooking: "Buchung stornieren",
    cancelBookingButton: "Stornieren",
    cancelBookingConfirmMessage:
      "Wählen Sie 'Weiter', um diese Buchung zu stornieren.",
    cancelBookingFailedMessage: "Buchungsstornierung fehlgeschlagen.",
    cancelBookingSuccessMessage: "Die Buchung wurde erfolgreich storniert.",
    cancelledAlert: "Die Transaktion wurde von Ihrer Bank storniert.",
    cancelledBookings: "Stornierte Buchungen",
    cancelledResultHeader: "Your payment was cancelled",
    checkConfirmBooking: "Bestätigen",
    child: "Kind",
    children: "Kinder",
    childrenAges: "Alter der Kinder",
    city: "Stadt",
    clear: "Löschen",
    clearSelection: "Ausgewählte(s) Zimmer löschen",
    clearSelectionConfirmMessage:
      "Wählen Sie 'Weiter', um '{0}' ausgewählte(s) Zimmer löschen",
    close: "Schließen",
    code: "Code",
    companyName: "Name der Firma",
    confirm: "Bestätigen",
    continue: "Weiter",
    cottage: "Ferienhaus",
    country: "Land",
    createNewBooking: "Neue Buchung erstellen",
    creatingBookingFailed: "Buchung erstellen fehlgeschlagen",
    defaultRefused:
      "Your payment was refused. Please check if you entered the form correctly, and try again.",
    departure: "Abreise",
    depositRequired: "Anzahlung erforderlich",
    disabledFacilities: "Behindertengerechte Einrichtungen",
    done: "Fertig",
    doubleBed: "Doppelbett ",
    dueNow: "Sie bezahlen jetzt",
    email: "E-Mail",
    emailAddress: "E-Mail Adresse",
    estimatedTimeArrival: "Ankunftszeit",
    extraLargeBed: "Extra großes Bett",
    firstName: "Vorname",
    flexibleCancellation: "Flexible Stornierung",
    freeCancellation: "Kostenlose Stornierung",
    from: "ab",
    fullyPrepaid: "Vollständig vorausbezahlt",
    garanteeNow: "Jetzt garantieren",
    guaranteedByCc: "Garantiert per Kreditkarte",
    guest: "Guest",
    guestDetails: "Gast-Details",
    guests: "Gäste",
    hotel: "Hotel",
    hotelRoom: "Hotelzimmer",
    infant: "Kleinkind",
    infants: "Kleinkinder",
    invalid: "Ungültig",
    invalidDateRange: "Bitte wählen Sie An- und Abreisedatum aus",
    invalidEmailError: "Die eingegebene E-Mail Adresse ist nicht gültig",
    invalidFormInputsMessage:
      "Das Formular enthält ungültige oder fehlende Werte",
    invalidPhoneNumber: "Das erste Zeichen sollte entweder + oder 0 sein",
    invalidValue: "Ungültiger Wert",
    jacuzzi: "Jacuzzi",
    kingSizeBed: "Kingsize-Bett",
    login: "Einloggen",
    loginErrorMessage:
      "Anmeldung fehlgeschlagen. Die eingegebene Kombination aus E-Mail-Adresse und PIN ist falsch.",
    logout: "Abmelden",
    loadingDataFailed: "Fehler beim laden der Daten",
    name: "Name",
    nextRoom: "Nächstes Zimmer",
    night: "Nacht",
    nights: "Nächte",
    nights_plur: "Nächte",
    noAvailableRoomsFound: "Keine Zimmer verfügbar",
    noBookingsFound: "Keine Buchungen gefunden.",
    noDataAvailable: "Keine Daten verfügbar",
    noDataFound: "Keine Daten gefunden",
    noPackagesFound: "Keine Pakete gefunden",
    noPaymentMethodsAvailable: "Keine Zahlungsmethoden verfügbar.",
    noPaymentMethodsAvailable: "No payment methods available.",
    noPetsAllowed: "Keine Haustiere erlaubt",
    noRoomsFound: "Keine Zimmer gefunden",
    notAvailable: "Nicht verfügbar",
    noUpComingVisitsFound: "Keine bevorstehenden Besuche gefunden.",
    numberOfAdults: "{0} Erwachsene",
    numberOfChildren: "{0} Kinder",
    numberOfInfants: "{0} Kleinkinder",
    numberOfPersons: "{0} Personen",
    options: "Optionen",
    packages: "Pakete",
    pageNotFound: "Seite nicht gefunden",
    pay: "Bezahlung",
    payInHotel: "Bezahlen im Hotel",
    payInHotelDescription:
      "Sie bezahlen bei Ihrer Ankunft an der Hotelrezeption.",
    payLater: "Später bezahlen",
    payLaterDescription:
      "Ihre Kreditkarte wird nicht belastet, wir benutzen die Kartendaten nur um Ihre Reservierung zu garantieren.",
    payment: "Payment",
    payMentDetails: "Zahlungsdetails",
    paymentGuarantee: "Payment guarantee",
    payNow: "Jetzt bezahlen",
    payNowDescription: "Sie zahlen direkt nach Abschluss Ihrer Reservierung.",
    payOptionsTitle: "Wann möchten Sie bezahlen?",
    pendingAlert:
      "We received your reservation and are waiting for the payment to be completed.",
    pendingResultHeader: "Your payment is pending",
    perAdultPerNight: "Pro Erwachsener pro Nacht",
    perAdultPerStay: "Pro Erwachsener pro Aufenthalt",
    percentagePerStay: "Prozentsatz pro Aufenthalt",
    percentagePerStayPerAdult: "Prozentsatz pro Aufenthalt pro Erwachsener",
    perChildPerNight: "Pro Kind pro Nacht",
    perChildPerStay: "Pro Kind pro Aufenthalt",
    perInfantPerNight: "Pro kleinkind pro Nacht",
    perInfantPerStay: "Pro kleinkind pro Aufenthalt",
    perNight: "Pro Nacht",
    perPersonPerNight: "Pro Person pro Nacht",
    perPersonPerStay: "Pro Person pro Aufenthalt",
    person: "Person",
    persons: "Personen",
    perStay: "Pro Aufenthalt",
    perUnit: "Pro Einheit",
    perUnitPerAdult: "Pro Einheit pro Erwachsener",
    perUnitPerAdultPerNight: "Pro Einheit pro Erwachsener pro Nacht",
    perUnitPerNight: "Pro Einheit pro Nacht",
    petsAllowed: "Haustiere erlaubt",
    phoneNumber: "Telefonnummer",
    postalCode: "Postleitzahl",
    privacyPolicy: "der Datenschutzerklärung",
    processingBooking: "Buchung bearbeiten",
    promoCode: "Aktionscode/Unternehmenscode/Rabattcode",
    queenSizeBed: "Doppelbett",
    rate: "Rate",
    readLess: "Weniger lesen",
    readMore: "Mehr lesen",
    reason: "Reason",
    refusalReason_2: "Refused",
    refusalReason_3: "Referral",
    refusalReason_4: "Acquirer Error",
    refusalReason_5: "Blocked Card",
    refusalReason_6: "Expired Card",
    refusalReason_7: "Invalid Amount",
    refusalReason_8: "Invalid Card Number",
    refusalReason_9: "Issuer Unavailable",
    refusalReason_10: "Not supported",
    refusalReason_11: "3D Not Authenticated",
    refusalReason_12: "Not enough balance",
    refusalReason_14: "Acquirer Fraud",
    refusalReason_15: "Cancelled",
    refusalReason_16: "Shopper Cancelled",
    refusalReason_17: "Invalid Pin",
    refusalReason_18: "Pin tries exceeded",
    refusalReason_19: "Pin validation not possible",
    refusalReason_20: "FRAUD",
    refusalReason_21: "Not Submitted",
    refusalReason_22: "FRAUD-CANCELLED",
    refusalReason_23: "Transaction Not Permitted",
    refusalReason_24: "CVC Declined",
    refusalReason_25: "Restricted Card",
    refusalReason_26: "Revocation Of Auth",
    refusalReason_27: "Declined Non Generic",
    refusalReason_28: "Withdrawal amount exceeded",
    refusalReason_29: "Withdrawal count exceeded",
    refusalReason_31: "Issuer Suspected Fraud",
    refusalReason_32: "AVS Declined",
    refusalReason_33: "Card requires online pin",
    refusalReason_34: "No checking account available on Card",
    refusalReason_35: "No savings account available on Card",
    refusalReason_36: "Mobile PIN required",
    refusalReason_37: "Contactless fallback",
    refusalReason_38: "Authentication required",
    refusalReason_39: "RReq not received from DS",
    refusedAlert: "The transaction was refused by your bank.",
    refusedResultHeader: "Your payment was refused",
    removeOption: "Option löschen",
    removeOptionConfirmMessage: "Wählen Sie 'Weiter', um '{0}' zu entfernen.",
    removeRoom: "Zimmer entfernen",
    removeRoomBookingConfirmMessage:
      "Wählen Sie 'Weiter', um dieses Zimmer zu entfernen.",
    removeRoomsGuestsRoomConfirmMessage:
      "Wählen Sie 'Weiter', um dieses Zimmer zu entfernen.",
    requiredFieldError: "Dieses Feld ist erforderlich",
    reservation: "Reservation",
    retrieveDataErrorMessage: "We couldn't retrieve the data for your payment.",
    retrieveDataErrorTitle: "Retrieving data failed",
    room: "Zimmer",
    rooms: "Zimmer",
    roomTypeFilter: "Zimmertyp-Filter",
    search: "Suchen",
    searchCriteria: "Suchkriterien",
    selectedDatesPerAdult: "Pro Erwachsener",
    selectedDatesPerChild: "Pro Kind",
    selectedDatesPerInfant: "Pro kleinkind",
    selectedDatesPerPerson: "Pro Person",
    selectedDatesPerStay: "Pro Einheit",
    selectOptionsTitle: "Extras",
    selectPackage: "Wählen Sie ein Paket",
    selectPackageWarning:
      "Wenn Sie dieses Paket wählen, wird Ihre Aufenthaltsdauer auf {0} Nächte festgelegt.",
    selectYourHotel: "Wählen Sie Ihr Hotel",
    selectYourRoom: "Wählen Sie Ihr Zimmer",
    selfCatering: "Selbstbedienung",
    sharedBathroom: "Gemeinschaftsbad",
    shower: "Dusche",
    showRoomImages: "Bilder des Zimmers",
    showPackageImages: "Bilder des Pakets",
    singleBed: "Einzelbett",
    sofaBed: "Schlafsofa",
    specialRequests: "Besondere Wünsche",
    stayDates: "Aufenthaltsdaten",
    stayDatesLabel: "Ankunft ~ Abreise",
    studio: "Studio",
    suite: "Suite",
    surName: "Nachname",
    teaCoffee: "Tee und Kaffee",
    termsConditions: "den Allgemeinen Geschäftsbedingungen",
    terrace: "Terrasse",
    thankYouP1:
      "Danke für Ihre Buchung! Sie erhalten in Kürze Ihre Bestätigung per E-Mail.",
    thankYouP2:
      "Bitte kontaktieren Sie uns, wenn Sie Fragen haben oder wir Ihnen weiterhelfen können.",
    thankYouP3: "Mit freundlichen Grüßen,",
    thankYouTitle: "Vielen Dank",
    time: "Zeit",
    today: "Heute",
    toOverview: "Zur Übersicht",
    tv: "TV",
    twinBeds: "Zwei Einzelbetten",
    undefined: "Undefiniert",
    undefinedError: "Undefinierter Fehler",
    units: "Zahl",
    unknown: "Unbekannt",
    upComingVisits: "Anstehende Besuche",
    userLoginIntro:
      "Melden Sie sich mit Ihrer E-Mail-Adresse und Ihrer PIN an, um die über diese Website vorgenommenen Reservierungen zu verwalten.",
    viewRates: "Preise",
    villa: "Villa",
    wifi: "WIFI",
    yourBookings: "Ihre Buchungen",
    yourDetails: "Ihre Angaben",
    yourDiscount: "Ihr Rabatt: ",
    yourTotal: "Ihr Gesamtbetrag",
  },
  "nn-NO": {
    "1adult": "1 vaksen",
    "1child": "1 barn",
    "1infant": "1 småbarn",
    "1person": "1 person",
    "404message": "Sida med url {0} eksisterer ikkje",
    add: "Legg til",
    additionalServices: "Ytterlegare avgifter og skattar",
    address: "Adresse",
    addRoom: "Legg til rom",
    adult: "Vaksen",
    adults: "Vaksne",
    agreeTo: "Eg godtar",
    airconditioning: "Aircondition",
    all: "Alle",
    allRoomsSelected: "Du har allereie valt ditt/dine rom.",
    amount: "Beløp",
    apartment: "Leilegheit",
    arrival: "Ankomst",
    arrivalDate: "Ankomstdato",
    authorisedResultHeader: "Takk!",
    available: "Tilgjengeleg",
    back: "tilbake",
    backToPrevious: "Tilbake til forrige side",
    balcony: "Balkong",
    bath: "Bad",
    bathShower: "Bad og dusj",
    book: "Bestill",
    bookerIsGuestLabel: "Eg er gjest i dette rommet",
    bookingCreatedSuccessfully: "Bestillinga di var vellukka",
    bookingSummary: "Oppsummering av bestillinga",
    bunkBeds: "Køyesengar",
    businessBookingLabel: "Bedriftsbestilling",
    cancel: "Avbryt",
    cancelBooking: "Avbestilla bestillinga",
    cancelBookingButton: "Avbestilla",
    cancelBookingConfirmMessage:
      "Vel 'Fortsett' for å avbestilla denne bestillinga.",
    cancelBookingFailedMessage: "Avbestilling av bestillinga mislykkast.",
    cancelBookingSuccessMessage: "Bestillinga har vorte kansellert.",
    cancelledAlert: "Transaksjonen blei kansellert av banken din.",
    cancelledBookings: "Avbestilte bestillingar",
    cancelledResultHeader: "Betalinga di blei kansellert",
    checkConfirmBooking: "Bekrefte",
    child: "Barn",
    children: "Barn",
    childrenAges: "Alder på barna",
    city: "By",
    clear: "Slett",
    clearSelection: "Fjern valde rom",
    clearSelectionConfirmMessage:
      "Vel 'Fortsett' for å fjerne dei valde romma.",
    close: "Lukk",
    code: "Kode",
    companyName: "Firmanamn",
    confirm: "Bekrefte",
    continue: "Fortsett",
    cottage: "Feriebustad",
    country: "Land",
    createNewBooking: "Opprett ny bestilling",
    creatingBookingFailed: "Oppretting av bestilling var mislykka",
    defaultRefused:
      "Betalinga di blei avvist. Sjekk om du fyllte ut skjemaet riktig, og prøv igjen.",
    departure: "Avreise",
    depositRequired: "Forskot er naudsynt",
    disabledFacilities: "Fasilitetar for funksjonshemma",
    done: "Ferdig",
    doubleBed: "Dobbeltseng",
    dueNow: "Forfell no",
    email: "E-post",
    emailAddress: "E-post adresse",
    estimatedTimeArrival: "Ankomsttid",
    extraLargeBed: "Ekstra stor seng",
    firstName: "Fornamn",
    flexibleCancellation: "Fleksibel avbestilling",
    freeCancellation: "Gratis avbestilling",
    from: "frå",
    fullyPrepaid: "Fullt førehandsbetalt",
    garanteeNow: "Garanti no",
    guaranteedByCc: "Garantert med kredittkort",
    guest: "Gjest",
    guestDetails: "Gjestedetaljar",
    guests: "Gjester",
    hotel: "Hotell",
    hotelRoom: "Hotellrom",
    infant: "Småbarn",
    infants: "Småbarn",
    invalid: "Ugyldig",
    invalidDateRange: "Vennligst velg ankomst- og avreisedato",
    invalidEmailError: "Den oppgitte e-postadressa er ikkje gyldig",
    invalidFormInputsMessage:
      "Skjemaet inneheld ugyldige eller manglande verdiar",
    invalidPhoneNumber: "Det første teiknet må vera anten + eller 0",
    invalidValue: "Ugyldig verdi",
    kingSizeBed: "King size seng",
    login: "Logg inn",
    loginErrorMessage:
      "Pålogging mislykkast. Kombinasjonen av e-postadresse og PIN-kode er feil.",
    logout: "Logg av",
    loadingDataFailed: "Lasting av data var mislykka",
    name: "Namn",
    nextRoom: "Neste rom",
    night: "natt",
    nights: "Netter",
    nights_plur: "netter",
    noAvailableRoomsFound: "Ingen tilgjengelige rom vart funne",
    noBookingsFound: "Ingen bestillingar funne.",
    noDataAvailable: "Ingen data tilgjengeleg",
    noDataFound: "Ingen data funne",
    noPackagesFound: "Ingen pakkar funnet",
    noPaymentMethodsAvailable: "Ingen betalingsmetodar tilgjengeleg.",
    noPaymentMethodsAvailable: "Ingen betalingsmetodar tilgjengeleg.",
    noPetsAllowed: "Kjæledyr er ikkje tillate",
    noRoomsFound: "Ingen rom funne",
    notAvailable: "Ikkje tilgjengeleg",
    noUpComingVisitsFound: "Ingen kommande besøk funne.",
    numberOfAdults: "{0} vaksne",
    numberOfChildren: "{0} barn",
    numberOfInfants: "{0} småbarn",
    numberOfPersons: "{0} personar",
    options: "Alternativ",
    packages: "Pakkar",
    pageNotFound: "Sida vart ikke funnen",
    pay: "Betal",
    payInHotel: "Betala på hotellet",
    payInHotelDescription:
      "Du betaler ved framkomst i resepsjonen til hotellet.",
    payLater: "Betala seinare",
    payLaterDescription:
      "Kortet ditt vil ikke bli belastet, vi trenger kun kortdataene dine for å garantere din reservasjon.",
    payment: "Betaling",
    payMentDetails: "Betalingsinformasjon",
    paymentGuarantee: "Betalingsgaranti",
    payNow: "Betal no",
    payNowDescription:
      "Du betaler rett etter at du har fullført reservasjonen.",
    payOptionsTitle: "Når vil du betale?",
    pendingAlert:
      "Vi mottok reservasjonen din og ventar på at betalinga skal bli fullført.",
    pendingResultHeader: "Betalinga di ventar",
    perAdultPerNight: "Per vaksen per natt",
    perAdultPerStay: "Per vaksen per opphald",
    percentagePerStay: "Prosentdel per opphald",
    percentagePerStayPerAdult: "Prosentdel per opphald per vaksen",
    perChildPerNight: "Per barn per natt",
    perChildPerStay: "Per barn per opphald",
    perInfantPerNight: "Per småbarn per natt",
    perInfantPerStay: "Per småbarn per opphald",
    perNight: "Per natt",
    perPersonPerNight: "Per person per natt",
    perPersonPerStay: "Per person per opphald",
    person: "Person",
    persons: "Personar",
    perStay: "Per opphald",
    perUnit: "Per eining",
    perUnitPerAdult: "Per eining per vaksen",
    perUnitPerAdultPerNight: "Per eining per vaksen per natt",
    perUnitPerNight: "Per eining per natt",
    petsAllowed: "Kjæledyr tillete",
    phoneNumber: "Telefonnummer",
    postalCode: "Postnummer",
    privacyPolicy: "personvernreglene",
    processingBooking: "Behandlar bestillinga",
    promoCode: "Kampanje/bedrifts-/rabattkode",
    queenSizeBed: "Queen size seng",
    rate: "Pris",
    readLess: "Les mindre",
    readMore: "Les meir",
    reason: "Grunn",
    refusalReason_2: "Avvist",
    refusalReason_3: "Referanse",
    refusalReason_4: "Ervervar Feil",
    refusalReason_5: "Blokkert kort",
    refusalReason_6: "Utløpt kort",
    refusalReason_7: "Ugyldig beløp",
    refusalReason_8: "Ugyldig kortnummer",
    refusalReason_9: "Utstedar utilgjengelig",
    refusalReason_10: "Støttast ikkje",
    refusalReason_11: "3D ikkje autentisert",
    refusalReason_12: "Ikkje nok tilgjengeleg",
    refusalReason_14: "Svindel med eigar",
    refusalReason_15: "Avbrote",
    refusalReason_16: "Kjøpar kansellert",
    refusalReason_17: "Ugyldig PIN",
    refusalReason_18: "Tal pin-forsøk er overskridne",
    refusalReason_19: "Pin-validering ikkje mogleg",
    refusalReason_20: "SVINDEL",
    refusalReason_21: "Ikkje sendt inn",
    refusalReason_22: "SVINDEL-KANSELLERT",
    refusalReason_23: "Transaksjon ikkje tilleten",
    refusalReason_24: "CVC avvist",
    refusalReason_25: "Begrensa kort",
    refusalReason_26: "Tilkalling av godkjenning",
    refusalReason_27: "Avvist ikke generisk",
    refusalReason_28: "Uttaksbeløpet er overskride",
    refusalReason_29: "Uttakstall overskride",
    refusalReason_31: "Utstedar mistenkt for svindel",
    refusalReason_32: "AVS avvist",
    refusalReason_33: "Kort krev online pin",
    refusalReason_34: "Ingen brukskonto tilgjengeleg på kortet",
    refusalReason_35: "Ingen sparekonto tilgjengeleg på kortet",
    refusalReason_36: "Mobil PIN påkrevd",
    refusalReason_37: "Kontaktfri fallback",
    refusalReason_38: "Autentisering påkrevd",
    refusalReason_39: "RReq ikke mottatt fra DS",
    refusedAlert: "Transaksjonen blei avvist av banken din.",
    refusedResultHeader: "Betalinga din blei avvist",
    removeOption: "Fjern alternativ",
    removeOptionConfirmMessage: "Vel 'Fortsett' for å fjerne '{0}'.",
    removeRoom: "Fjern rom",
    removeRoomBookingConfirmMessage: "Vel 'Fortsett' for å fjerne dette rommet",
    removeRoomsGuestsRoomConfirmMessage:
      "Vel 'Fortsett' for å fjerne dette rommet",
    requiredFieldError: "Dette feltet er obligatorisk.",
    reservation: "Reservasjon",
    retrieveDataErrorMessage: "Vi kunne ikkje hente data for betalinga di.",
    retrieveDataErrorTitle: "Henting av data var mislykka",
    room: "Rom",
    rooms: "Rom",
    roomTypeFilter: "Filtar for romtype",
    search: "Søk",
    searchCriteria: "Søkekriteriar",
    selectedDatesPerAdult: "Per voksen",
    selectedDatesPerChild: "Per barn",
    selectedDatesPerInfant: "Per småbarn",
    selectedDatesPerPerson: "Per person",
    selectedDatesPerStay: "Per eining",
    selectOptionsTitle: "Forbetre opphaldet ditt",
    selectPackage: "Vel ein pakke",
    selectPackageWarning:
      "Viss du vel denne pakken, blir lengda sett på opphaldet til {0} netter.",
    selectYourHotel: "Vel hotell",
    selectYourRoom: "Vel rommet ditt",
    selfCatering: "Sjølvhushald",
    sharedBathroom: "Delt bad",
    shower: "Dusj",
    showRoomImages: "Vis rombilder",
    showPackageImages: "Vis pakkebilder",
    singleBed: "Enkeltseng",
    sofaBed: "Sovesofa",
    specialRequests: "Spesielle ønskjer",
    stayDates: "Datoar for opphald",
    stayDatesLabel: "Ankomst ~ avreise",
    studio: "Studio",
    suite: "Suite",
    surName: "Etternamn",
    teaCoffee: "Te og kaffi",
    termsConditions: "vilkra",
    terrace: "Terrasse",
    thankYouP1:
      "Takk for bestillinga di! Du vil snart motta stadfestinga på e-post.",
    thankYouP2:
      "Ikkje nøl med å kontakte oss dersom du har spørsmål eller treng meir informasjon. Vi ser frem til å sjå deg snart.",
    thankYouP3: "Helsing",
    thankYouTitle: "Takk",
    time: "Tid",
    today: "I dag",
    toOverview: "Til oversikt",
    tv: "TV",
    twinBeds: "2 enkeltsengar",
    undefined: "Udefinert",
    undefinedError: "Udefinert feil",
    units: "Tal",
    unknown: "Ukjent",
    upComingVisits: "Kommande besøk",
    userLoginIntro:
      "Logg inn med e-postadressa og PIN-koden din for å administrera reservasjonar som er gjorde via denne nettstaden.",
    viewRates: "Prisar",
    villa: "Villa",
    wifi: "WIFI",
    yourBookings: "Bestillingane dine",
    yourDetails: "Dine detaljar",
    yourDiscount: "Din rabatt: ",
    yourTotal: "Din totalsum",
  },
  "nb-NO": {
    "1adult": "1 voksen",
    "1child": "1 barn",
    "1infant": "1 spedbarn",
    "1person": "1 person",
    "404message": "Siden med url {0} eksisterer ikke",
    add: "Legg til",
    additionalServices: "Ytterlige skatter og avgifter",
    address: "Adresse",
    addRoom: "Legg til rom",
    adult: "Voksen",
    adults: "Voksne",
    agreeTo: "Jeg godtar",
    airconditioning: "Aircondition",
    all: "Alle",
    allRoomsSelected: "Du har allerede valgt ditt/dine rom.",
    amount: "Beløp",
    apartment: "Leilighet",
    arrival: "Ankomst",
    arrivalDate: "Ankomstdato",
    authorisedResultHeader: "Takk!",
    available: "Tilgjengelig",
    back: "tilbake",
    backToPrevious: "Tilbake til forrige side",
    balcony: "Balkong",
    bath: "Bad",
    bathShower: "Bad og dusj",
    book: "Bestill",
    bookerIsGuestLabel: "Jeg er gjest i dette rommet",
    bookingCreatedSuccessfully: "Bestillingen din var vellykket",
    bookingSummary: "Oppsummering",
    bunkBeds: "Køyesenger",
    businessBookingLabel: "Bedriftsbestilling",
    cancel: "Avbryt",
    cancelBooking: "Avbestille bestillingen",
    cancelBookingButton: "Avbestille",
    cancelBookingConfirmMessage:
      "Velg 'Fortsett' for å avbestille denne bestillingen.",
    cancelBookingFailedMessage: "Avbestilling av bestillingen mislyktes.",
    cancelBookingSuccessMessage: "Bestillingen har blitt kansellert.",
    cancelledAlert: "Transaksjonen ble kansellert av banken din.",
    cancelledBookings: "Avbestilte bestillinger",
    cancelledResultHeader: "Betalingen din ble kansellert",
    checkConfirmBooking: "Bekrefte",
    child: "Barn",
    children: "Barn",
    childrenAges: "Alder på barna",
    city: "Poststed",
    clear: "Slett",
    clearSelection: "Fjern valgte rom",
    clearSelectionConfirmMessage:
      "Velg 'Fortsett' for å fjerne de valgte rommene.",
    close: "Lukk",
    code: "Kode",
    companyName: "Firmanavn",
    confirm: "Bekrefte",
    continue: "Fortsett",
    cottage: "Feriebolig",
    country: "Land",
    createNewBooking: "Opprett ny bestilling",
    creatingBookingFailed: "Oppretting av bestilling var mislykket",
    defaultRefused:
      "Betalingen din ble avvist. Sjekk om du fylte ut skjemaet riktig, og prøv igjen.",
    departure: "Avreise",
    depositRequired: "Forskuddsbetaling",
    disabledFacilities: "Fasiliteter for funksjonshemmede",
    done: "Ferdig",
    doubleBed: "Dobbeltseng",
    dueNow: "Til betaling nå",
    email: "Epost",
    emailAddress: "Epost-adresse",
    estimatedTimeArrival: "Ankomsttid",
    extraLargeBed: "Ekstra stor seng",
    firstName: "Fornavn",
    flexibleCancellation: "Fleksibel avbestilling",
    freeCancellation: "Gratis avbestilling",
    from: "fra",
    fullyPrepaid: "Fullt forhåndsbetalt",
    garanteeNow: "Garanti nå",
    guaranteedByCc: "Garantert med kredittkort",
    guest: "Gjest",
    guestDetails: "Gjestedetaljer",
    guests: "Gjester",
    hotel: "Hotell",
    hotelRoom: "Hotellrom",
    infant: "Spedbarn",
    infants: "Spedbarn",
    invalid: "Ugyldig",
    invalidDateRange: "Vennligst velg ankomst- og avreisedat",
    invalidEmailError: "Den oppgitte e-postadressen er ikke gyldig",
    invalidFormInputsMessage:
      "Skjemaet inneholder ugyldig eller manglende informasjon",
    invalidPhoneNumber: "Det første tegnet må være enten + eller 0",
    invalidValue: "Ugyldig verdi",
    jacuzzi: "Jacuzzi",
    kingSizeBed: "King size seng",
    login: "Logg inn",
    loginErrorMessage:
      "Pålogging mislyktes. Kombinasjonen av e-postadresse og PIN-kode er feil.",
    logout: "Logg av",
    loadingDataFailed: "Noe gikk galt",
    name: "Navn",
    nextRoom: "Neste rom",
    night: "natt",
    nights: "Netter",
    nights_plur: "netter",
    noAvailableRoomsFound: "Vi fant ingen tilgjengelige rom",
    noBookingsFound: "Ingen bestillinger funnet.",
    noDataAvailable: "Ingen informasjon tilgjengelig",
    noDataFound: "Ingen informasjon er funnet",
    noPackagesFound: "Ingen pakker funnet",
    noPaymentMethodsAvailable: "Ingen betalingsmetoder er tilgjengelig.",
    noPaymentMethodsAvailable: "Ingen betalingsmetoder er tilgjengelig.",
    noPetsAllowed: "Kjæledyr er ikke tillatt",
    noRoomsFound: "Ingen rom funnet",
    notAvailable: "Ikke tilgjengelig",
    noUpComingVisitsFound: "Ingen kommende besøk funnet.",
    numberOfAdults: "{0} voksne",
    numberOfChildren: "{0} barn",
    numberOfInfants: "{0} spedbarn",
    numberOfPersons: "{0} personer",
    options: "Alternativ",
    packages: "Pakker",
    pageNotFound: "Siden ble ikke funnet",
    pay: "Betale",
    payInHotel: "Betale på hotellet",
    payInHotelDescription: "Du betaler ved ankomst i hotellets resepsjon.",
    payLater: "Betale senere",
    payLaterDescription:
      "Kortet ditt vil ikke bli belastet, vi trenger kun kortdataene dine for å garantere din reservasjon.",
    payment: "Betaling",
    payMentDetails: "Betalingsinformasjon",
    paymentGuarantee: "Betalingsgaranti",
    payNow: "Betal nå",
    payNowDescription:
      "Du betaler rett etter at du har fullført reservasjonen.",
    payOptionsTitle: "Når vil du betale?",
    pendingAlert:
      "Vi mottok reservasjonen din og venter på at betalingen skal bli fullført.",
    pendingResultHeader: "Betalingen din ventar",
    perAdultPerNight: "Pr voksen pr natt",
    perAdultPerStay: "Pr voksen pr opphold",
    percentagePerStay: "Prosentdel pr opphold",
    percentagePerStayPerAdult: "Prosontdel pr opphold pr voksen",
    perChildPerNight: "Pr barn pr natt",
    perChildPerStay: "Pr barn pr opphold",
    perInfantPerNight: "Pr spedbarn pr natt",
    perInfantPerStay: "Pr spedbarn pr opphold",
    perNight: "Pr natt",
    perPersonPerNight: "Pr person pr natt",
    perPersonPerStay: "Pr person pr opphold",
    person: "Person",
    persons: "Personer",
    perStay: "Pr opphold",
    perUnit: "Pr stk",
    perUnitPerAdult: "Pr stk pr voksen",
    perUnitPerAdultPerNight: "Pr stk pr voksen pr natt",
    perUnitPerNight: "Pr stk pr natt",
    petsAllowed: "Kjæledyr er tillatt",
    phoneNumber: "Telefonnummer",
    postalCode: "Postnummer",
    privacyPolicy: "personvernreglane",
    processingBooking: "Behandler bestillingen",
    promoCode: "Kampanje/bedrifts-/rabattkode",
    queenSizeBed: "Queen size seng",
    rate: "Pris",
    readLess: "Les mindre",
    readMore: "Les mer",
    reason: "Årsak",
    refusalReason_2: "Avvist",
    refusalReason_3: "Referanse",
    refusalReason_4: "Ervervar Feil",
    refusalReason_5: "Blokkert kort",
    refusalReason_6: "Utløpt kort",
    refusalReason_7: "Ugyldig beløp",
    refusalReason_8: "Ugyldig kortnummer",
    refusalReason_9: "Utsteder ikke tilgjengelig",
    refusalReason_10: "Støttes ikke",
    refusalReason_11: "3D ikke autentisert",
    refusalReason_12: "Ikke nok tilgjengelig",
    refusalReason_14: "Svindel med eier",
    refusalReason_15: "Avbrutt",
    refusalReason_16: "Kjøper kansellert",
    refusalReason_17: "Ugyldig PIN",
    refusalReason_18: "Tal pin-forsøk er overskridet",
    refusalReason_19: "Pin-validering ikke mulig",
    refusalReason_20: "SVINDEL",
    refusalReason_21: "Ikke sendt inn",
    refusalReason_22: "SVINDEL-KANSELLERT",
    refusalReason_23: "Transaksjon ikke tillat",
    refusalReason_24: "CVC avvist",
    refusalReason_25: "Begrenset kort",
    refusalReason_26: "Tilkalling av godkjenning",
    refusalReason_27: "Avvist ikke generisk",
    refusalReason_28: "Uttaksbeløpet er overskridet",
    refusalReason_29: "Uttakstall overskridet",
    refusalReason_31: "Utsteder mistenkt for svindel",
    refusalReason_32: "AVS avvist",
    refusalReason_33: "Kort krev online pin",
    refusalReason_34: "Ingen brukskonto tilgjengelig på kortet",
    refusalReason_35: "Ingen sparekonto tilgjengelig på kortet",
    refusalReason_36: "Mobil PIN påkrevd",
    refusalReason_37: "Kontaktfri fallback",
    refusalReason_38: "Autentisering påkrevd",
    refusalReason_39: "RReq ikke mottatt fra DS",
    refusedAlert: "Transaksjonen ble avvist av banken din.",
    refusedResultHeader: "Betalingen din ble avvist",
    removeOption: "Fjern alternativ",
    removeOptionConfirmMessage: "Velg 'Fortsett' for å fjerne '{0}'.",
    removeRoom: "Fjern rom",
    removeRoomBookingConfirmMessage:
      "Velg 'Fortsett' for å fjerne dette rommet",
    removeRoomsGuestsRoomConfirmMessage:
      "Velg 'Fortsett' for å fjerne dette rommet",
    requiredFieldError: "Dette feltet er obligatorisk.",
    reservation: "Reservasjon",
    retrieveDataErrorMessage: "Vi kunne ikke hente data for betalingen din.",
    retrieveDataErrorTitle: "Henting av data var mislykket",
    room: "Rom",
    rooms: "Rom",
    roomTypeFilter: "Filter for romtype",
    search: "Søk",
    searchCriteria: "Søkekriterier",
    selectedDatesPerAdult: "Pr voksen",
    selectedDatesPerChild: "Pr barn",
    selectedDatesPerInfant: "Pr spedbarn",
    selectedDatesPerPerson: "Pr person",
    selectedDatesPerStay: "Pr stk",
    selectOptionsTitle: "Få mer ut av oppholdet ditt",
    selectPackage: "Velg en pakke",
    selectPackageWarning:
      "Hvis du velger denne pakken, settes lengden på oppholdet til {0} netter.",
    selectYourHotel: "Velg hotell",
    selectYourRoom: "Velg rom",
    selfCatering: "Selvhushold",
    sharedBathroom: "Delt bad",
    shower: "Dusj",
    showRoomImages: "Vis rombilder",
    showPackageImages: "Vis pakkebilder",
    singleBed: "Enkeltseng",
    sofaBed: "Sovesofa",
    specialRequests: "Spesielle ønsker",
    stayDates: "Velg datoer for opphold",
    stayDatesLabel: "Ankomst ~ avreise",
    studio: "Studio",
    suite: "Suite",
    surName: "Etternavn",
    teaCoffee: "Te og kaffe",
    termsConditions: "vilkårene",
    terrace: "Terrasse",
    thankYouP1:
      "Takk for din bestilling! Du vil snart motta bekreftelse på e-post.",
    thankYouP2:
      "Ikke nøl med å kontakte oss dersom du har spørsmål eller trenger mer informasjon. Vi ser frem til å se deg snart!",
    thankYouP3: "Hilsen",
    thankYouTitle: "Takk",
    time: "Tid",
    today: "I dag",
    toOverview: "Til oversikt",
    tv: "TV",
    twinBeds: "2 enkeltsenger",
    undefined: "Udefinert",
    undefinedError: "Udefinert feil",
    units: "Antall",
    unknown: "Ukjent",
    upComingVisits: "Kommende besøk",
    userLoginIntro:
      "Logg inn med e-postadressen og PIN-koden din for å administrere reservasjoner som er gjort via dette nettstedet.",
    viewRates: "Priser",
    villa: "Villa",
    wifi: "WIFI",
    yourBookings: "Dine bestillinger",
    yourDetails: "Dine detaljer",
    yourDiscount: "Din rabatt: ",
    yourTotal: "Din totalsum",
  },
  "fi-FI": {
    "1adult": "1 aikuinen",
    "1child": "1 lapsi",
    "1infant": "1 vauva",
    "1person": "1 henkilö",
    "404message": "Sivua URL-osoitteella {0} ei ole olemassa",
    add: "Lisää",
    additionalServices: "Lisämaksut ja verot",
    address: "Osoite",
    addRoom: "Lisää huone",
    adult: "Aikuinen",
    adults: "Aikuiset",
    agreeTo: "Hyväksyn",
    airconditioning: "Ilmastointi",
    all: "Kaikki",
    allRoomsSelected: "Olet jo valinnut huoneesi",
    amount: "Summa",
    apartment: "Huoneisto",
    arrival: "Saapuminen",
    arrivalDate: "Saapumispäivä",
    authorisedResultHeader: "Kiitos!",
    available: "Saatavilla",
    back: "takaisin",
    backToPrevious: "Takaisin edelliselle sivulle",
    balcony: "Parveke",
    bath: "kylpyamme",
    bathShower: "Kylpyamme ja suihku",
    book: "Varaa",
    bookerIsGuestLabel: "Olen vieras tässä huoneessa",
    bookingCreatedSuccessfully: "Varaus luotu onnistuneesti",
    bookingSummary: "Varauksen yhteenveto",
    bunkBeds: "kerrossänky",
    businessBookingLabel: "Liiketoiminnan varaus",
    cancel: "Peruuta",
    cancelBooking: "Peruuttaa varauksen",
    cancelBookingButton: "Peruuta",
    cancelBookingConfirmMessage:
      "Valitse 'Jatka' peruuttaaksesi tämän varauksen.",
    cancelBookingFailedMessage: "Varauksen peruutus epäonnistui.",
    cancelBookingSuccessMessage: "Varaus on onnistuneesti peruutettu.",
    cancelledAlert: "Pankkisi peruutti tapahtuman.",
    cancelledBookings: "Peruutetut varaukset",
    cancelledResultHeader: "Maksusi peruutettiin",
    checkConfirmBooking: "Vahvista",
    child: "Lapsi",
    children: "Lapset",
    childrenAges: "Lasten ikä",
    city: "Kaupunki",
    clear: "Poista",
    clearSelection: "Tyhjennä valitut huoneet",
    clearSelectionConfirmMessage:
      "Valitse 'Jatka' poistaaksesi valitut huoneet.",
    close: "Sulje",
    code: "Koodi",
    companyName: "Yrityksen nimi",
    confirm: "Vahvista",
    continue: "Jatka",
    cottage: "Loma-asunto",
    country: "Maa",
    createBookingFailed: "Varauksen luominen epäonnistui",
    createNewBooking: "Luo uusi varaus",
    defaultRefused:
      "Maksusi hylättiin. Tarkista, syötitkö lomakkeen tiedot oikein, ja yritä uudelleen.",
    departure: "Lähtö",
    depositRequired: "Ennakkomaksu vaaditaan",
    disabledFacilities: "Lisävarusteet liikuntarajoitteisille",
    done: "Valmis",
    doubleBed: "Parivuode",
    dueNow: "Erääntymässä nyt",
    email: "Sähköposti",
    emailAddress: "Sähköpostiosoite",
    estimatedTimeArrival: "Saapumisaika",
    extraLargeBed: "Erittäin suuri sänky",
    firstName: "Etunimi",
    flexibleCancellation: "Joustava peruutus",
    freeCancellation: "Ilmainen peruutus",
    from: "alkaen",
    fullyPrepaid: "Täysin maksettu",
    garanteeNow: "Takaa nyt",
    guaranteedByCc: "Taattu luottokortilla",
    guest: "Vieras",
    guestDetails: "Asiakkaan tiedot",
    guests: "Asiakkaat",
    hotel: "Hotelli",
    hotelRoom: "Hotellihuone",
    infant: "Vauva",
    infants: "Vauvat",
    invalid: "Virheellinen",
    invalidDateRange: "Valitse saapumis- ja lähtöpäivä",
    invalidEmailError: "Syötetty sähköpostiosoite ei ole voimassa",
    invalidFormInputsMessage:
      "lomake sisältää virheellisiä tai puuttuvia arvoja",
    invalidPhoneNumber: "Ensimmäisen merkin on oltava joko + tai 0",
    invalidValue: "Virheellinen arvo",
    jacuzzi: "Jacuzzi",
    kingSizeBed: "King-size-vuode",
    login: "Kirjaudu sisään",
    loginErrorMessage:
      "Kirjautuminen epäonnistui. Syötetty sähköpostiosoite ja PIN-koodi ovat väärin.",
    logout: "Kirjaudu ulos",
    loadingDataFailed: "Tietojen lataus epäonnistui",
    name: "Nimi",
    nextRoom: "Seuraava huone",
    night: "yö",
    nights: "Yöt",
    nights_plur: "yöt",
    noAvailableRoomsFound: "Vapaita huoneita ei löytynyt",
    noBookingsFound: "Varauksia ei löytynyt.",
    noDataAvailable: "Tietoja ei ole saatavilla",
    noDataFound: "Tietoja ei löydy",
    noPackagesFound: "Paketteja ei löytynyt",
    noPaymentMethodsAvailable: "Maksutapoja ei ole saatavilla.",
    noPaymentMethodsAvailable: "Maksutapoja ei ole saatavilla.",
    noPetsAllowed: "Lemmikit eivät ole sallittuja",
    noRoomsFound: "Huoneita ei löytynyt",
    notAvailable: "Ei saatavilla",
    noUpComingVisitsFound: "Tulevia vierailuja ei löytynyt.",
    numberOfAdults: "{0} aikuista",
    numberOfChildren: "{0} lasta",
    numberOfInfants: "{0} vauvat",
    numberOfPersons: "{0} henkilöä",
    options: "Vaihtoehdot",
    packages: "Paketit",
    pageNotFound: "Sivua ei löydy",
    pay: "Maksa",
    payInHotel: "Maksa hotellissa",
    payInHotelDescription:
      "Maksat majoituksen saapuessasi hotellin vastaanotossa.",
    payLater: "Maksa myöhemmin",
    payLaterDescription:
      "Korttiasi ei veloiteta, vaadimme vain korttitietosi varauksen takaamiseksi.",
    payment: "Maksu",
    payMentDetails: "Maksutiedot",
    paymentGuarantee: "Maksutakuu",
    payNow: "Maksa nyt",
    payNowDescription: "Maksat heti varauksenteon jälkeen.",
    payOptionsTitle: "Milloin haluat maksaa?",
    pendingAlert: "Saimme varauksesi ja odotamme maksun suorittamista.",
    pendingResultHeader: "Maksusi odottaa",
    perAdultPerNight: " Per aikuinen per yö",
    perAdultPerStay: "Per aikuinen per oleskelu",
    percentagePerStay: "Prosentti per oleskelu",
    percentagePerStayPerAdult: "Prosentti per oleskelu per aikuinen",
    perChildPerNight: "Per lapsi per yö",
    perChildPerStay: "Per lapsi per oleskelu",
    perInfantPerNight: "Per vauva per yö",
    perInfantPerStay: "Per vauva per oleskelu",
    perNight: "Per yö",
    perPersonPerNight: "Per henkilö per yö",
    perPersonPerStay: "Per henkilö per oleskelu",
    person: "Henkilö",
    persons: "Henkilöä",
    perStay: "Per oleskelu",
    perUnit: "Per yksikkö",
    perUnitPerAdult: "Per yksikkö per aikuinen",
    perUnitPerAdultPerNight: "Per yksikkö per aikuinen per yö",
    perUnitPerNight: "Per yksikkö per yö",
    petsAllowed: "Lemmikit sallittu",
    phoneNumber: "Puhelinnumero",
    postalCode: "Postinumero",
    privacyPolicy: "tietosuojakäytännön",
    processingBooking: "Käsittelemme varausta",
    promoCode: "Tarjouskoodi/Yrityskoodi/Alennuskoodi",
    queenSizeBed: "Queen-size-vuode",
    rate: "Hinta",
    readLess: "Lue vähemmän",
    readMore: "Lue lisää",
    reason: "Syy",
    refusalReason_2: "hylätty",
    refusalReason_3: "Referral",
    refusalReason_4: "Virhe",
    refusalReason_5: "Estetty kortti",
    refusalReason_6: "Vanhentunut kortti",
    refusalReason_7: "Virheellinen summa",
    refusalReason_8: "Virheellinen kortin numero",
    refusalReason_9: "Myöntäjä ei saatavilla",
    refusalReason_10: "Ei tueta",
    refusalReason_11: "3D Ei todennettu",
    refusalReason_12: "Ei tarpeeksi saldoa",
    refusalReason_14: "Acquirer Fraud",
    refusalReason_15: "Peruutettu",
    refusalReason_16: "Ostaja perunut",
    refusalReason_17: "Virheellinen PIN-koodi",
    refusalReason_18: "Pin-yritykset ylitetty",
    refusalReason_19: "Pinn vahvistus ei ole mahdollista",
    refusalReason_20: "PETOS",
    refusalReason_21: "Ei lähetetty",
    refusalReason_22: "PETOS - PERUUTETTU",
    refusalReason_23: "Tapahtuma ei sallittu",
    refusalReason_24: "CVC hylätty",
    refusalReason_25: "Rajoitettu kortti",
    refusalReason_26: "Todennusten peruuttaminen",
    refusalReason_27: "Hylätty ei yleinen",
    refusalReason_28: "Nostosumma ylitetty",
    refusalReason_29: "Nostojen määrä ylitetty",
    refusalReason_31: "Liikkeeseenlaskijan epäilty petos",
    refusalReason_32: "AVS hylätty",
    refusalReason_33: "Kortti vaatii verkkotunnuksen",
    refusalReason_34: "Kortilla ei ole shekkitiliä",
    refusalReason_35: "Kortilla ei ole säästötiliä",
    refusalReason_36: "Mobile PIN vaaditaan",
    refusalReason_37: "Kontaktiton varmistus",
    refusalReason_38: "Todennus vaaditaan",
    refusalReason_39: "RReq ei vastaanotettu DS:ltä",
    refusedAlert: "Pankkisi hylkäsi tapahtuman.",
    refusedResultHeader: "Maksusi hylättiin",
    removeOption: "Poista vaihtoehto",
    removeOptionConfirmMessage: "Valitse 'Jatka' poistaaksesi '{0}'.",
    removeRoom: "Poista huone",
    removeRoomBookingConfirmMessage: "Poista tämä huone valitsemalla 'Jatka'",
    removeRoomsGuestsRoomConfirmMessage:
      "Poista tämä huone valitsemalla 'Jatka'",
    requiredFieldError: "Tämä kenttä on pakollinen.",
    reservation: "Varaus",
    retrieveDataErrorMessage: "Emme voineet noutaa maksusi tietoja.",
    retrieveDataErrorTitle: "Tietojen nouto epäonnistui",
    room: "Huone",
    rooms: "Huoneet",
    roomTypeFilter: "Huonetyypin suodatin",
    search: "Hae",
    searchCriteria: "Hakuehdot",
    selectedDatesPerAdult: "Per aikuinen",
    selectedDatesPerChild: "Per lapsi",
    selectedDatesPerInfant: "Per vauva",
    selectedDatesPerPerson: "Per henkilö",
    selectedDatesPerStay: "Per yksikkö",
    selectOptionsTitle: "Paranna oleskeluasi",
    selectPackage: "Valitse paketti",
    selectPackageWarning:
      "Jos valitset tämän paketin, oleskelun pituudeksi asetetaan {0} yötä.",
    selectYourHotel: "Valitse hotelli",
    selectYourRoom: "Valitse huoneesi",
    selfCatering: "Omatoimiruokailu",
    sharedBathroom: "Jaettu kylpyhuone",
    shower: "suihku",
    showRoomImages: "Näytä huoneen kuvat",
    showPackageImages: "Näytä pakettien kuvat",
    singleBed: "Yhden hengen sänky",
    sofaBed: "Vuodesohva",
    specialRequests: "Erikoispyynnöt",
    stayDates: "Oleskelun päivämäärät",
    stayDatesLabel: "Saapuminen ~ lähtö",
    studio: "Studio",
    suite: "Suite",
    surName: "Sukunimi",
    teaCoffee: "Teetä ja kahvia",
    termsConditions: "käyttöehdot",
    terrace: "Terassi",
    thankYouP1: "Kiitos varauksestasi! Saat vahvistuksen sähköpostitse pian.",
    thankYouP2:
      "Älä epäröi ottaa meihin yhteyttä, jos sinulla on kysyttävää tai voimme olla vielä avuksi. Odotamme näkevämme sinut pian.",
    thankYouP3: "Terveisin",
    thankYouTitle: "Kiitos",
    time: "Aika",
    today: "Tänään",
    toOverview: "Yhteenvetoon",
    tv: "TV",
    twinBeds: "Erilliset vuoteet",
    undefined: "Määrittämätön",
    undefinedError: "Määrittämätön virhe",
    units: "Numero",
    unknown: "Tuntematon",
    upComingVisits: "Tulevat vierailut",
    userLoginIntro:
      "Kirjaudu sisään sähköpostiosoitteellasi ja PIN-koodillasi hallinnoidaksesi tämän verkkosivuston kautta tehtyjä varauksia.",
    viewRates: "Hinnat",
    villa: "Villa",
    wifi: "WIFI",
    yourBookings: "Varaukset",
    yourDetails: "Omat tiedot",
    yourDiscount: "Alennuksesi: ",
    yourTotal: "Kokonaishinta",
  },
  "sv-SE": {
    "1adult": "1 vuxen",
    "1child": "1 barn",
    "1infant": "1 spädbarn",
    "1person": "1 person",
    "404message": "Sidan med webbadressen {0} finns inte",
    add: "Lägg till",
    additionalServices: "Ytterligare avgifter och skatter",
    address: "Adress",
    addRoom: "Lägg till rum",
    adult: "Vuxen",
    adults: "Vuxna",
    agreeTo: "Jag godkänner",
    airconditioning: "Luftkonditionering",
    all: "Alla",
    allRoomsSelected: "Du har redan valt dina rum.",
    amount: "Belopp",
    apartment: "Lägenhet",
    arrival: "Ankomst",
    arrivalDate: "Ankomstdatum",
    authorisedResultHeader: "Tack!",
    available: "Tillgänglig",
    back: "tillbaka",
    backToPrevious: "Tillbaka till föregående sida",
    balcony: "Balkong",
    bath: "Badkar",
    bathShower: "Badkar & Dusch",
    book: "Reservera",
    bookerIsGuestLabel: "Jag är gäst i det här rummet",
    bookingCreatedSuccessfully: "Bokning skapad framgångsrikt",
    bookingSummary: "Bokningsöversikt",
    bunkBeds: "Våningssängar",
    businessBookingLabel: "Affärsbokning",
    cancel: "Avboka",
    cancelBooking: "Avboka bokning",
    cancelBookingButton: "Avboka",
    cancelBookingConfirmMessage: "Välj 'Fortsätt' för att avbryta bokningen.",
    cancelBookingFailedMessage: "Avbokning av bokningen misslyckades.",
    cancelBookingSuccessMessage: "Bokningen har framgångsrikt avbokats.",
    cancelledAlert: "Transaktionen avbröts av din bank.",
    cancelledBookings: "Avbokade bokningar",
    cancelledResultHeader: "Din betalning avbröts",
    checkConfirmBooking: "Bekräfta",
    child: "Barn",
    children: "Barn",
    childrenAges: "Barnens ålder",
    city: "Stad",
    clear: "Radera",
    clearSelection: "Rensa valda rum",
    clearSelectionConfirmMessage: "Välj 'Fortsätt' för att ta bort valda rum.",
    close: "Stäng",
    code: "Kod",
    companyName: "Företagsnamn",
    confirm: "Bekräfta",
    continue: "Fortsätt",
    cottage: "Semesterbostad",
    country: "Land",
    createBookingFailed: "Det gick inte att skapa bokning",
    createNewBooking: "Skapa ny bokning",
    defaultRefused:
      "Din betalning nekades. Kontrollera om du fyllde i formuläret korrekt och försök igen.",
    departure: "Avgång",
    depositRequired: "Förhandsbetalning krävs",
    disabledFacilities: "Hjälpmedel för funktionshindrade",
    done: "gjort",
    doubleBed: "Dubbelsäng",
    dueNow: "Förfaller nu",
    email: "E-post",
    emailAddress: "E-postadress",
    estimatedTimeArrival: "Ankomst tid",
    extraLargeBed: "Extra stor säng",
    firstName: "Förnamn",
    flexibleCancellation: "Flexibel avbokning",
    freeCancellation: "Gratis avbokning",
    from: "från",
    fullyPrepaid: "Fullt förhadsbetald",
    garanteeNow: "Garantera nu",
    guaranteedByCc: "Garanterat med kreditkort",
    guest: "Gäst",
    guestDetails: "Gästdetaljer",
    guests: "Gäster",
    hotel: "Hotell",
    hotelRoom: "Hotellrum",
    infant: "Spädbarn",
    infants: "Spädbarn",
    invalid: "Ogiltigt",
    invalidDateRange: "Välj ankomst- och avresedatum",
    invalidEmailError: "Den angivna e-postadressen är inte giltig",
    invalidFormInputsMessage:
      "Formuläret innehåller ogiltiga eller saknade värden",
    invalidPhoneNumber: "Det första tecknet måste vara antingen + eller 0",
    invalidValue: "Ogiltigt värde",
    jacuzzi: "Jacuzzi",
    kingSizeBed: "King size säng",
    login: "Logga in",
    loginErrorMessage:
      "Inloggningen misslyckades. Den angivna kombinationen av e-postadress och PIN-kod är felaktig.",
    logout: "Logga ut",
    loadingDataFailed: "Det gick inte att ladda data",
    name: "Namn",
    nextRoom: "Nästa rum",
    night: "natt",
    nights: "Nätter",
    nights_plur: "nätter",
    noAvailableRoomsFound: "Inga lediga rum hittades",
    noBookingsFound: "Inga bokningar hittades.",
    noDataAvailable: "Ingen data tillgänglig",
    noDataFound: "Inga data hittades",
    noPackagesFound: "Inga paket hittades",
    noPaymentMethodsAvailable: "Inga betalningsmetoder tillgängliga.",
    noPaymentMethodsAvailable: "Inga betalningsmetoder tillgängliga.",
    noPetsAllowed: "Husdjur är inte tillåtna",
    noRoomsFound: "Inga rum hittades",
    notAvailable: "Ej tillgängligt",
    noUpComingVisitsFound: "Inga kommande besök hittades.",
    numberOfAdults: "{0} vuxna",
    numberOfChildren: "{0} barn",
    numberOfInfants: "{0} spädbarn",
    numberOfPersons: "{0} personer",
    options: "Alternativ",
    packages: "Paket",
    pageNotFound: "Sidan hittades inte",
    pay: "Betala",
    payInHotel: "Betala på hotellet",
    payInHotelDescription: "Du betalar vid ankomsten i hotellreceptionen.",
    payLater: "Betala senare",
    payLaterDescription:
      "Ditt kort kommer inte att debiteras, vi behöver bara dina kortuppgifter för att garantera din bokning.",
    payment: "Betalning",
    payMentDetails: "Betalningsinformation",
    paymentGuarantee: "Betalningsgaranti",
    payNow: "Betala nu",
    payNowDescription: "Du betalar direkt efter att du genomfört din bokning.",
    payOptionsTitle: "När vill du betala?",
    pendingAlert:
      "Vi har tagit emot din bokning och väntar på att betalningen ska slutförs.",
    pendingResultHeader: "Din betalning väntar",
    perAdultPerNight: "Per vuxen per natt",
    perAdultPerStay: "Per vuxen per vistelse",
    percentagePerStay: "Procentandel per vistelse",
    percentagePerStayPerAdult: "Procentandel per vistelse per vuxen",
    perChildPerNight: "Per barn per natt",
    perChildPerStay: "Per barn per vistelse",
    perInfantPerNight: "Per spädbarn per natt",
    perInfantPerStay: "Per spädbarn per vistelse",
    perNight: "Per natt",
    perPersonPerNight: "Per person per natt",
    perPersonPerStay: "Per person per vistelse",
    person: "Person",
    persons: "Personer",
    perStay: "Per vistelse",
    perUnit: "Per enhet",
    perUnitPerAdult: "Per enhet per vuxen",
    perUnitPerAdultPerNight: "Per enhet per vuxen per natt",
    perUnitPerNight: "Per enhet per natt",
    petsAllowed: "Husdjur tillåtna",
    phoneNumber: "Telefonnummer",
    postalCode: "Postnummer",
    privacyPolicy: "sekretesspolicyn",
    processingBooking: "Bearbetar bokning",
    promoCode: "Kampanjkod/företagskod/rabattkod",
    queenSizeBed: "Queen size säng",
    rate: "Pris",
    readLess: "Läs mindre",
    readMore: "Läs mer",
    reason: "Anledning",
    refusalReason_2: "Avvisade",
    refusalReason_3: "Remiss",
    refusalReason_4: "Ett fel har uppkommit",
    refusalReason_5: "Spärrat kort",
    refusalReason_6: "Utgått kort",
    refusalReason_7: "Ogiltigt belopp",
    refusalReason_8: "Ogiltigt kortnummer",
    refusalReason_9: "Utfärdaren ej tillgänglig",
    refusalReason_10: "Stöds inte",
    refusalReason_11: "3D inte autentiserat",
    refusalReason_12: "Inte tillräckligt med saldo",
    refusalReason_14: "Förvärvarebedrägeri",
    refusalReason_15: "Inställd",
    refusalReason_16: "Cancellerad av köparen",
    refusalReason_17: "Ogiltig pin-kod",
    refusalReason_18: "Pin-försök har överskridits",
    refusalReason_19: "Pin-validering inte möjlig",
    refusalReason_20: "BEDRÄGERI",
    refusalReason_21: "Inte skickat",
    refusalReason_22: "BEDRÄGERI-CANCELLERAD",
    refusalReason_23: "Transaktion ej tillåten",
    refusalReason_24: "CVC avvisades",
    refusalReason_25: "Begränsat kort",
    refusalReason_26: "Återkallelse av auktoritet",
    refusalReason_27: "Avvisad icke generisk",
    refusalReason_28: "Uttagsbeloppet har överskridits",
    refusalReason_29: "Antalet uttag har överskridits",
    refusalReason_31: " Misstänkt bedrägeri",
    refusalReason_32: "AVS avvisades",
    refusalReason_33: "Kort kräver online-pin",
    refusalReason_34: "Inget check-konto tillgängligt på kortet",
    refusalReason_35: "Inget sparkonto tillgängligt på kortet",
    refusalReason_36: "Mobil PIN krävs",
    refusalReason_37: "Kontaktfri reserv",
    refusalReason_38: "Autentisering krävs",
    refusalReason_39: "RReq inte mottaget från DS",
    refusedAlert: "Transaktionen nekades av din bank.",
    refusedResultHeader: "Din betalning nekades",
    removeOption: "Ta bort alternativ",
    removeOptionConfirmMessage: "Välj 'Fortsätt' för att ta bort '{0}'.",
    removeRoom: "Ta bort rum",
    removeRoomBookingConfirmMessage:
      "Välj 'Fortsätt' för att ta bort det här rummet",
    removeRoomsGuestsRoomConfirmMessage:
      "Välj 'Fortsätt' för att ta bort det här rummet",
    requiredFieldError: "Detta fält är obligatoriskt.",
    reservation: "Reservation",
    retrieveDataErrorMessage: "Vi kunde inte hämta data för din betalning.",
    retrieveDataErrorTitle: "Det gick inte att hämta data",
    room: "Rum",
    rooms: "Rum",
    roomTypeFilter: "Filter för rumstyp",
    search: "Sök",
    searchCriteria: "Sökkriterier",
    selectedDatesPerAdult: "Per vuxen",
    selectedDatesPerChild: "Per barn",
    selectedDatesPerInfant: "Per spädbarn",
    selectedDatesPerPerson: "Per person",
    selectedDatesPerStay: "Per enhet",
    selectOptionsTitle: "Förbättra din vistelse",
    selectPackage: "Välj ett paket",
    selectPackageWarning:
      "Om du väljer detta paket kommer din vistelselängd att sättas till {0} nätter.",
    selectYourHotel: "Välj ditt hotell",
    selectYourRoom: "Välj ditt rum",
    selfCatering: "Självhushåll",
    sharedBathroom: "Delat badrum",
    shower: "Dusch",
    showRoomImages: "Visa rumsbilder",
    showPackageImages: "Visa paketbilder",
    singleBed: "Enkelsäng",
    sofaBed: "Bäddsoffa",
    specialRequests: "Särskilda önskemål",
    stayDates: "Datum för vistelsen",
    stayDatesLabel: "Ankomst ~ avgång",
    studio: "Studio",
    suite: "Suite",
    surName: "Efternamn",
    teaCoffee: "Te och kaffe",
    termsConditions: "villkoren",
    terrace: "Terrass",
    thankYouP1:
      "Tack för din bokning! Du kommer att få din bekräftelse via e-post inom kort.",
    thankYouP2:
      "Tveka inte att kontakta oss om du har några frågor eller om vi kan vara till ytterligare hjälp. Vi ser fram emot att se dig snart.",
    thankYouP3: "Hälsningar",
    thankYouTitle: "Tack",
    time: "Tid",
    today: "Idag",
    toOverview: "Till översikten",
    tv: "TV",
    twinBeds: "Tvåbäddsrum",
    undefined: "Odefinierad",
    undefinedError: "Odefinierat fel",
    units: "Antal",
    unknown: "Okänd",
    upComingVisits: "Kommande besök",
    userLoginIntro:
      "Logga in med din e-postadress och PIN-kod för att hantera bokningar som gjorts via denna webbplats.",
    viewRates: "Priser",
    villa: "Villa",
    wifi: "WIFI",
    yourBookings: "Bokningar",
    yourDetails: "Dina uppgifter",
    yourDiscount: "Din rabatt: ",
    yourTotal: "Fullständigt pris",
  },
  "da-DK": {
    "1adult": "1 voksen",
    "1child": "1 barn",
    "1infant": "1 spædbarn",
    "1person": "1 person",
    "404message": "Siden med url {0} eksisterer ikke",
    add: "Tilføj",
    additionalServices: "Yderligere gebyrer og afgifter",
    address: "Adresse",
    addRoom: "Tilføj værelse",
    adult: "Voksen",
    adults: "Voksne",
    agreeTo: "Jeg accepterer",
    airconditioning: "Aircondition",
    all: "Alle",
    allRoomsSelected: "Du har allerede valgt dit/dine rum.",
    amount: "Beløb",
    apartment: "Lejlighed",
    arrival: "Ankomst",
    arrivalDate: "Ankomstdato",
    authorisedResultHeader: "Tak!",
    available: "Tilgængelig",
    back: "Tilbage",
    backToPrevious: "Tilbage til forrige side",
    balcony: "Balkon",
    bath: "Bad",
    bathShower: "Bad & Brusebad",
    book: "Bekræft",
    bookerIsGuestLabel: "Jeg er gæst i dette rum",
    bookingCreatedSuccessfully: "Booking oprettet med succes",
    bookingSummary: "Reserveringsoversigt",
    bunkBeds: "Køjesenge",
    businessBookingLabel: "Business booking",
    cancel: "Annuller",
    cancelBooking: "Annullere booking",
    cancelBookingButton: "Annuller",
    cancelBookingConfirmMessage:
      "Vælg 'Fortsæt' for at annullere denne booking.",
    cancelBookingFailedMessage: "Annullering af booking mislykkedes.",
    cancelBookingSuccessMessage: "Bookingen er blevet annulleret.",
    cancelledAlert: "Transaktionen blev annulleret af din bank.",
    cancelledBookings: "Aflyste bookinger",
    cancelledResultHeader: "Din betaling blev annulleret",
    checkConfirmBooking: "Bekræft",
    child: "Barn",
    children: "Børn",
    childrenAges: "Børn i alderen",
    city: "By",
    clear: "Slet",
    clearSelection: "Ryd udvalgte rum",
    clearSelectionConfirmMessage:
      "Vælg 'Fortsæt' for at fjerne det eller de valgte rum.",
    close: "Luk",
    code: "Kode",
    companyName: "Virksomhedsnavn",
    confirm: "Bekræft",
    continue: "Fortsæt",
    cottage: "Feriebolig",
    country: "Land",
    createBookingFailed: "Oprettelse af booking mislykkedes",
    createNewBooking: "Opret ny booking",
    defaultRefused:
      "Din betaling blev afvist. Tjek venligst, om du har indtastet formularen korrekt, og prøv igen.",
    departure: "Afrejse",
    depositRequired: "Depositum påkrævet",
    disabledFacilities: "Handicapfaciliteter",
    done: "Færdig",
    doubleBed: "Dobbeltseng",
    dueNow: "Forfalder nu",
    email: "E-mail",
    emailAddress: "E-mail adresse",
    estimatedTimeArrival: "ETA",
    extraLargeBed: "Ekstra stor seng",
    firstName: "Fornavn",
    flexibleCancellation: "Fleksibel annullering",
    freeCancellation: "Gratis annullering",
    from: "fra",
    fullyPrepaid: "Fuldt forudbetalt",
    garanteeNow: "Garanti nu",
    guaranteedByCc: "Garanteret med kreditkort",
    guest: "Gæst",
    guestDetails: "Gæstedetaljer",
    guests: "Gæster",
    hotel: "Hotel",
    hotelRoom: "Hotelværelse",
    infant: "Spædbarn",
    infants: "Spædbørn",
    invalid: "Ugyldig",
    invalidDateRange: "Vælg venligst ankomst- og afrejsedato",
    invalidEmailError: "Den indtastede e-mailadresse er ikke gyldig",
    invalidFormInputsMessage:
      "Formularen indeholder ugyldige eller manglende værdier",
    invalidPhoneNumber: "Det første tegn skal være enten + eller 0",
    invalidValue: "Ugyldig værdi",
    jacuzzi: "Jacuzzi",
    kingSizeBed: "King size seng",
    login: "Log ind",
    loginErrorMessage:
      "Login mislykkedes. Den indtastede kombination af e-mailadresse og PIN-kode er forkert.",
    logout: "Log af",
    loadingDataFailed: "Indlæsning af data mislykkedes",
    name: "Navn",
    nextRoom: "Næste værelse",
    night: "nat",
    nights: "Nætter",
    nights_plur: "nætter",
    noAvailableRoomsFound: "Ingen ledige værelser fundet",
    noBookingsFound: "Ingen bookinger fundet.",
    noDataAvailable: "Ingen data tilgængelige",
    noDataFound: "Ingen data fundet",
    noPackagesFound: "Ingen pakker fundet",
    noPaymentMethodsAvailable: "Ingen betalingsmetoder tilgængelige.",
    noPaymentMethodsAvailable: "Ingen betalingsmetoder tilgængelige.",
    noPetsAllowed: "Kæledyr er ikke tilladt",
    noRoomsFound: "Ingen værelser fundet",
    notAvailable: "Ikke tilgængelig",
    noUpComingVisitsFound: "Ingen kommende besøg fundet.",
    numberOfAdults: "{0} voksne",
    numberOfChildren: "{0} børn",
    numberOfInfants: "{0} spædbørn",
    numberOfPersons: "{0} personer",
    options: "Muligheder",
    packages: "Pakker",
    pageNotFound: "Siden blev ikke fundet",
    pay: "Betal",
    payInHotel: "Betal på hotellet",
    payInHotelDescription: "Du betaler ved ankomst i hotellets reception.",
    payLater: "Betal senere",
    payLaterDescription:
      "Dit kort vil ikke blive debiteret, vi kræver kun dine kortdata for at garantere din reservation.",
    payment: "Betaling",
    payMentDetails: "Betalingsdetaljer",
    paymentGuarantee: "Betalingsgaranti",
    payNow: "Betal nu",
    payNowDescription:
      "Du betaler lige efter at have gennemført din reservation.",
    payOptionsTitle: "Hvornår vil du betale?",
    pendingAlert:
      "Vi har modtaget din reservation og venter på, at betalingen er gennemført.",
    pendingResultHeader: "Din betaling afventer",
    perAdultPerNight: "Per voksen per nat",
    perAdultPerStay: "Per voksen pr. ophold",
    percentagePerStay: "Procentdel pr. ophold",
    percentagePerStayPerAdult: "Procentdel pr. ophold pr. voksen",
    perChildPerNight: "Per barn per nat",
    perChildPerStay: "Pr. barn pr. ophold",
    perInfantPerNight: "Per spædbarn per nat",
    perInfantPerStay: "Pr. spædbarn pr. ophold",
    perNight: "Per nat",
    perPersonPerNight: "Pr. person pr. nat",
    perPersonPerStay: "Pr. person pr. ophold",
    person: "Person",
    persons: "Personer",
    perStay: "Per ophold",
    perUnit: "Per enhed",
    perUnitPerAdult: "Pr. enhed pr. voksen",
    perUnitPerAdultPerNight: "Pr. enhed pr. voksen pr. nat",
    perUnitPerNight: "Pr. enhed pr. nat",
    petsAllowed: "Kæledyr tilladt",
    phoneNumber: "Telefonnummer",
    postalCode: "Postnummer",
    privacyPolicy: "privatlivspolitik",
    processingBooking: "Behandler booking",
    promoCode: "Kampagnekode/Firmakode/Rabatkode",
    queenSizeBed: "Queen size seng",
    rate: "Rate",
    readLess: "Læs mindre",
    readMore: "Læs mere",
    reason: "Årsag",
    refusalReason_2: "Afvist",
    refusalReason_3: "Henvisning",
    refusalReason_4: "Acquirer Error",
    refusalReason_5: "Blokeret kort",
    refusalReason_6: "Udløbet kort",
    refusalReason_7: "Ugyldigt beløb",
    refusalReason_8: "Ugyldigt kortnummer",
    refusalReason_9: "Udsteder ikke tilgængelig",
    refusalReason_10: "Ikke understøttet",
    refusalReason_11: "3D ikke godkendt",
    refusalReason_12: "Ikke nok balance",
    refusalReason_14: "Acquirer Fraud",
    refusalReason_15: "Annulleret",
    refusalReason_16: "Shopper annulleret",
    refusalReason_17: "Ugyldig pinkode",
    refusalReason_18: "Pin-forsøg er overskredet",
    refusalReason_19: "Pin-validering ikke mulig",
    refusalReason_20: "FRAUD",
    refusalReason_21: "Ikke indsendt",
    refusalReason_22: "FRAUD-ANNULLERET",
    refusalReason_23: "Transaktion ikke tilladt",
    refusalReason_24: "CVC afvist",
    refusalReason_25: "Begrænset kort",
    refusalReason_26: "Tilbagekaldelse af godkendelse",
    refusalReason_27: "Afvist ikke generisk",
    refusalReason_28: "Tilbagetrækningsbeløb overskredet",
    refusalReason_29: "Tæller for tilbagetrækning overskredet",
    refusalReason_31: "Udsteder mistænkt svindel",
    refusalReason_32: "AVS afvist",
    refusalReason_33: "Kort kræver online pin",
    refusalReason_34: "Ingen checkkonto tilgængelig på kortet",
    refusalReason_35: "Ingen opsparingskonto tilgængelig på kortet",
    refusalReason_36: "Mobil PIN kræves",
    refusalReason_37: "Kontaktfri fallback",
    refusalReason_38: "Godkendelse påkrævet",
    refusalReason_39: "RReq ikke modtaget fra DS",
    refusedAlert: "Transaktionen blev afvist af din bank.",
    refusedResultHeader: "Din betaling blev afvist",
    removeOption: "Fjern mulighed",
    removeOptionConfirmMessage: "Vælg 'Fortsæt' for at fjerne '{0}'.",
    removeRoom: "Fjern værelse",
    removeRoomBookingConfirmMessage: "Vælg 'Fortsæt' for at fjerne dette rum",
    removeRoomsGuestsRoomConfirmMessage:
      "Vælg 'Fortsæt' for at fjerne dette rum",
    requiredFieldError: "Dette felt er påkrævet.",
    reservation: "Reservation",
    retrieveDataErrorMessage: "Vi kunne ikke hente dataene til din betaling.",
    retrieveDataErrorTitle: "Hentning af data mislykkedes",
    room: "Værelse",
    rooms: "Værelser",
    roomTypeFilter: "Rumtype-filter",
    search: "Søg",
    searchCriteria: "Søgekriterier",
    selectedDatesPerAdult: "Per voksen",
    selectedDatesPerChild: "Per barn",
    selectedDatesPerInfant: "Per spædbarn",
    selectedDatesPerPerson: "Per person",
    selectedDatesPerStay: "Per enhed",
    selectOptionsTitle: "Forbedre dit ophold",
    selectPackage: "Vælg en pakke",
    selectPackageWarning:
      "Hvis du vælger denne pakke, vil længden af dit ophold blive sat til {0} nætter.",
    selectYourHotel: "Vælg dit hotel",
    selectYourRoom: "Vælg dit værelse",
    selfCatering: "Selvforplejning",
    sharedBathroom: "Delt badeværelse",
    shower: "Bruser",
    showRoomImages: "Vis værelsesbilleder",
    showPackageImages: "Vis pakkebilleder",
    singleBed: "Enkeltseng",
    sofaBed: "Sovesofa",
    specialRequests: "Særlige anmodninger",
    stayDates: "Opholdsdatoer",
    stayDatesLabel: "Ankomst ~ Afrejse",
    studio: "Studio",
    suite: "Suite",
    surName: "Efternavn",
    teaCoffee: "Te og kaffe",
    termsConditions: "vilkårene og betingelserne",
    terrace: "Terrasse",
    thankYouP1:
      "Tak for din reservation! Du vil snart modtage din bekræftelse på e-mail.",
    thankYouP2:
      "Tøv ikke med at kontakte os, hvis du har spørgsmål, eller hvis vi kan hjælpe dig yderligere. Vi ser frem til at se dig snart.",
    thankYouP3: "Hilsen",
    thankYouTitle: "Tak",
    time: "Tid",
    today: "I dag",
    toOverview: "Til oversigt",
    tv: "TV",
    twinBeds: "2 enkeltsenge",
    undefined: "Udefineret",
    undefinedError: "Udefineret fejl",
    units: "Antal",
    unknown: "Ukendt",
    upComingVisits: "Kommende besøg",
    userLoginIntro:
      "Log ind med din e-mailadresse og pinkode for at administrere reservationer foretaget via denne hjemmeside.",
    viewRates: "Priser",
    villa: "Villa",
    wifi: "WIFI",
    yourBookings: "Reservationer",
    yourDetails: "Dine detaljer",
    yourDiscount: "Din rabat: ",
    yourTotal: "Din total",
  },
  "fr-FR": {
    "1adult": "1 adulte",
    "1child": "1 enfant",
    "1infant": "1 enfant",
    "1person": "1 personne",
    "404message": "La page avec l'url {0} n'existe pas",
    add: "Ajouter",
    additionalServices: "Frais et taxes supplémentaires",
    address: "Adresse",
    addRoom: "Ajouter une pièce",
    adult: "Adulte",
    adults: "Adultes",
    agreeTo: "J'accepte le",
    airconditioning: "Climatisation",
    all: "Tous",
    allRoomsSelected: "Vous avez déjà sélectionné votre/vos chambre(s).",
    amount: "Montant",
    apartment: "Appartement",
    arrival: "Arrivée",
    arrivalDate: "Date d'arrivée",
    authorisedResultHeader: "Merci!",
    available: "Disponible",
    back: "Retour",
    backToPrevious: "Retour à la page précédente",
    balcony: "Balcon",
    bath: "Bain",
    bathShower: "Bain & Douche",
    book: "Réserver",
    bookerIsGuestLabel: "J'occupe moi-même cette chambre",
    bookingCreatedSuccessfully: "Réservation créée avec succès",
    bookingSummary: "Résumé de la réservation",
    bunkBeds: "Lits superposés",
    businessBookingLabel: "Réservation professionnelle",
    cancel: "Annuler",
    cancelBooking: "Annuler la réservation",
    cancelBookingButton: "Annuler",
    cancelBookingConfirmMessage:
      "Sélectionnez 'Continuer' pour annuler cette réservation.",
    cancelBookingFailedMessage: "L'annulation de la réservation a échoué.",
    cancelBookingSuccessMessage: "La réservation a été annulée avec succès.",
    cancelledAlert: "La transaction a été annulée par votre banque.",
    cancelledBookings: "Réservations annulées",
    cancelledResultHeader: "Votre paiement a été annulé",
    checkConfirmBooking: "Vérifier et confirmer",
    child: "Enfant",
    children: "Enfants",
    childrenAges: "âge des enfants",
    city: "Ville",
    clear: "Effacer",
    clearSelection: "Effacer la ou les chambre(s) sélectionnée(s)",
    clearSelectionConfirmMessage:
      "Sélectionnez 'Continuer' pour supprimer la ou les chambres sélectionnées.",
    close: "Fermer",
    code: "Code",
    companyName: "Nom de l'entreprise",
    confirm: "Confirmer",
    continue: "Continuer",
    cottage: "Maison de vacances",
    country: "Pays",
    createNewBooking: "Créer une nouvelle réservation",
    creatingBookingFailed: "Échec de la création de la réservation",
    defaultRefused:
      "Votre paiement a été refusé. Veuillez vérifier si vous avez correctement saisi le formulaire et réessayer.",
    departure: "Départ",
    depositRequired: "Dépôt requis",
    disabledFacilities: "Installations pour handicapés",
    done: "Fini",
    doubleBed: "Lit double",
    dueNow: "Dû maintenant",
    email: "E-mail",
    emailAddress: "Adresse e-mail",
    estimatedTimeArrival: "Heure d'arrivée",
    extraLargeBed: "Lit extra large",
    firstName: "Prénom",
    flexibleCancellation: "Annulation flexible",
    freeCancellation: "Annulation gratuite",
    from: "de",
    fullyPrepaid: "Entièrement prépayé",
    garanteeNow: "Garantir maintenant",
    guaranteedByCc: "Garantie par carte de crédit",
    guest: "Voyageur",
    guestDetails: "Détails des voyageurs",
    guests: "Voyageurs",
    hotel: "Hôtel",
    hotelRoom: "Chambre d'hôtel",
    infant: "Bébé",
    infants: "Bébés",
    invalid: "Invalide",
    invalidDateRange: "Veuillez sélectionner la date d'arrivée et de depart",
    invalidEmailError: "L'adresse électronique saisie n'est pas valide",
    invalidFormInputsMessage: "Plage de dates invalide",
    invalidPhoneNumber: "Le premier caractère doit être + ou 0",
    invalidValue: "Plage de dates invalide",
    jacuzzi: "Jacuzzi",
    kingSizeBed: "Lit King size",
    login: "Se connecter",
    loginErrorMessage:
      "La connexion a échoué. La combinaison de l'adresse électronique et du code PIN saisi est incorrecte.",
    logout: "Déconnexion",
    loadingDataFailed: "Le chargement des données a échoué",
    name: "Nom",
    nextRoom: "Pièce suivante",
    night: "nuit",
    nights: "Nuits",
    nights_plur: "nuits",
    noAvailableRoomsFound: "Aucune chambre disponible n'a été trouvée",
    noBookingsFound: "Pas de réservations trouvées.",
    noDataAvailable: "Aucune donnée disponible",
    noDataFound: "ucune donnée trouvée",
    noPackagesFound: "Aucun paquet trouvé",
    noPaymentMethodsAvailable: "Aucun mode de paiement disponible.",
    noPaymentMethodsAvailable: "Aucun mode de paiement disponible.",
    noPetsAllowed: "Les animaux ne sont pas admis",
    noRoomsFound: "Aucune chambre trouvée",
    notAvailable: "Non disponible",
    noUpComingVisitsFound: "Aucune visite à venir n'a été trouvée.",
    numberOfAdults: "{0} adultes",
    numberOfChildren: "{0} enfents",
    numberOfInfants: "{0} bébés",
    numberOfPersons: "{0} personnes",
    options: "Options",
    packages: "Forfaits",
    pageNotFound: "Page introuvable",
    pay: "Payer",
    payInHotel: "Payer à l'hôtel",
    payInHotelDescription:
      "Vous paierez à votre arrivée à la réception de l'hôtel.",
    payLater: "Payer plus tard",
    payLaterDescription:
      "Votre carte ne sera pas débitée, nous n'avons besoin que des données de votre carte pour garantir votre réservation.",
    payment: "Paiement",
    payMentDetails: "Détails du paiement",
    paymentGuarantee: "Garantie de paiement",
    payNow: "Payer maintenant",
    payNowDescription:
      "Vous paierez juste après avoir terminé votre réservation.",
    payOptionsTitle: "Quand voulez-vous payer?",
    pendingAlert:
      "Nous avons reçu votre réservation et attendons que le paiement soit effectué.",
    pendingResultHeader: "Votre paiement est en attente",
    perAdultPerNight: "Par adulte et par nuit",
    perAdultPerStay: "Par adulte par séjour",
    percentagePerStay: "Pourcentage par séjour",
    percentagePerStayPerAdult: "Pourcentage par séjour par adulte",
    perChildPerNight: "Par enfant par nuit",
    perChildPerStay: "Par enfant par séjour",
    perInfantPerNight: "Par bébé par nuit",
    perInfantPerStay: "Par bébé par séjour",
    perNight: "Par nuit",
    perPersonPerNight: "Par personne par nuit",
    perPersonPerStay: "Par personne par séjour",
    person: "Personne",
    persons: "Personnes",
    perStay: "Par séjour",
    perUnit: "Par unité",
    perUnitPerAdult: "Par unité par adulte",
    perUnitPerAdultPerNight: "Par unité par adulte et par nuit",
    perUnitPerNight: "Par unité par nuit",
    petsAllowed: "Animaux autorisés",
    phoneNumber: "Numéro de téléphone",
    postalCode: "Code postal",
    privacyPolicy: "politique de confidentialité",
    processingBooking: "Traitement de la réservation",
    promoCode: "Code promo/d'entreprise/de réduction",
    queenSizeBed: "Lit queen size",
    rate: "Prix",
    readLess: "Lire moins",
    readMore: "En savoir plus",
    reason: "Raison",
    refusalReason_2: "Refusé",
    refusalReason_3: "référence",
    refusalReason_20: "FRAUDE",
    refusalReason_22: "FRAUDE-ANNULÉ",
    refusalReason_4: "Erreur de l'acquéreur",
    refusalReason_5: "Carte bloquée",
    refusalReason_6: "Carte expirée",
    refusalReason_7: "Montant invalide",
    refusalReason_8: "Numéro de carte invalide",
    refusalReason_9: "Émetteur indisponible",
    refusalReason_10: "Non pris en charge",
    refusalReason_11: "3D non authentifié",
    refusalReason_12: "Pas assez de solde",
    refusalReason_14: "Fraude de l'acquéreur",
    refusalReason_15: "Annulé",
    refusalReason_16: "Acheteur annulé",
    refusalReason_17: "Pin invalide",
    refusalReason_18: "Tentatives de code PIN dépassées",
    refusalReason_19: "Validation du code PIN impossible",
    refusalReason_21: "Non soumis",
    refusalReason_23: "Transaction non autorisée",
    refusalReason_24: "CVC refusé",
    refusalReason_25: "Carte restreinte",
    refusalReason_26: "Révocation de l'authentification",
    refusalReason_27: "Non générique refusé",
    refusalReason_28: "Montant du retrait dépassé",
    refusalReason_29: "Nombre de retraits dépassé",
    refusalReason_31: "Fraude présumée de l'émetteur",
    refusalReason_32: "AVS refusé",
    refusalReason_33: "La carte nécessite un code PIN en ligne",
    refusalReason_34: "Aucun compte courant disponible sur la carte",
    refusalReason_35: "Aucun compte d'épargne disponible sur la carte",
    refusalReason_36: "NIP mobile requis",
    refusalReason_37: "Repli sans contact",
    refusalReason_38: "Authentification requise",
    refusalReason_39: "RReq non reçu de DS",
    refusedAlert: "La transaction a été refusée par votre banque.",
    refusedResultHeader: "Votre paiement a été refusé",
    removeOption: "Supprimer l'option",
    removeOptionConfirmMessage:
      "Sélectionnez 'Continuer' pour supprimer '{0}'.",
    removeRoom: "Supprimer la salle",
    removeRoomBookingConfirmMessage:
      "Sélectionnez 'Continuer' pour supprimer cette chambre",
    removeRoomsGuestsRoomConfirmMessage:
      "Sélectionnez 'Continuer' pour supprimer cette chambre",
    requiredFieldError: "Ce champ est obligatoire.",
    reservation: "Réservation",
    retrieveDataErrorMessage:
      "Nous n'avons pas pu récupérer les données pour votre paiement.",
    retrieveDataErrorTitle: "La récupération des données a échoué",
    room: "Chambre",
    rooms: "Chambres",
    roomTypeFilter: "Filtre de type chambre",
    search: "Chercher",
    searchCriteria: "Critères de recherche",
    selectedDatesPerAdult: "Par adulte",
    selectedDatesPerChild: "Par enfant",
    selectedDatesPerInfant: "Par bébé",
    selectedDatesPerPerson: "Par personne",
    selectedDatesPerStay: "Par unité",
    selectOptionsTitle: "Enrichissez votre séjour",
    selectPackage: "Sélectionner un forfait",
    selectPackageWarning:
      "La sélection de ce forfait fixera votre séjour à {0} nuits.",
    selectYourHotel: "Sélectionnez votre hôtel",
    selectYourRoom: "Sélectionnez votre chambre",
    selfCatering: "Self Catering",
    sharedBathroom: "Salle de bain partagée",
    shower: "Douche",
    showRoomImages: "Afficher les images de la salle",
    showPackageImages: "Afficher les images des paquets",
    singleBed: "Lit simple",
    sofaBed: "Canapé-lit",
    specialRequests: "Demandes spéciales",
    stayDates: "Dates de séjour",
    stayDatesLabel: "Arrivée ~ départ",
    studio: "Studio",
    suite: "Suite",
    surName: "Nom de famille",
    teaCoffee: "Thé et café",
    termsConditions: "Conditions générales",
    terrace: "Terrasse",
    thankYouP1:
      "Merci pour votre réservation! Vous recevrez prochainement votre confirmation par e-mail.",
    thankYouP2:
      "N'hésitez pas à nous contacter si vous avez des questions ou si nous pouvons vous aider davantage. Nous espérons vous voir bientôt.",
    thankYouP3: "Cordialement",
    thankYouTitle: "Merci",
    time: "Heure",
    today: "Aujourd'hui",
    toOverview: "Vers l'aperçu",
    tv: "Télé",
    twinBeds: "Lit jumeaux",
    undefined: "Indéfini",
    undefinedError: "Erreur indéfinie",
    units: "Nombre",
    unknown: "Inconnu",
    upComingVisits: "Visites à venir",
    userLoginIntro:
      "Connectez-vous avec votre adresse e-mail et votre code PIN pour gérer les réservations effectuées sur ce site web.",
    viewRates: "Tarifs",
    villa: "Villa",
    wifi: "WIFI",
    yourBookings: "Réservations",
    yourDetails: "Vos coordonnées",
    yourDiscount: "Votre réduction: ",
    yourTotal: "Votre total",
  },
  "et-EE": {
    "1adult": "1 täiskasvanu",
    "1child": "1 laps",
    "1infant": "1 imik",
    "1person": "1 inimene",
    "404message": "Lehekülg URL-iga {0} ei eksisteeri",
    add: "Lisa",
    additionalServices: "Lisatasud ja maksud",
    address: "Aadress",
    addRoom: "Lisa tuba",
    adult: "Täiskasvanu",
    adults: "Täiskasvanud",
    agreeTo: "Nõustun",
    airconditioning: "Konditsioneer",
    all: "Kõik",
    allRoomsSelected: "Olete juba oma toa(d) valinud.",
    amount: "Summa",
    apartment: "Korter",
    arrival: "Saabumine",
    arrivalDate: "Saabumise kuupäev",
    authorisedResultHeader: "Täname!",
    available: "Saadaval",
    back: "Tagasi",
    backToPrevious: "Tagasi eelmisele lehele",
    balcony: "Rõdu",
    bath: "Vann",
    bathShower: "Vann ja dušš",
    book: "Broneeri",
    bookerIsGuestLabel: "Olen selle toa külaline",
    bookingCreatedSuccessfully: "Broneering on õnnestunud",
    bookingSummary: "Broneeringu kokkuvõte",
    bunkBeds: "Narivoodid",
    businessBookingLabel: "Äribroneering",
    cancel: "Tühista",
    cancelBooking: "Tühista broneering",
    cancelBookingButton: "Tühista",
    cancelBookingConfirmMessage: "Tühistamiseks valige 'Jätka'.",
    cancelBookingFailedMessage: "Broneeringu tühistamine ebaõnnestus.",
    cancelBookingSuccessMessage: "Broneering on edukalt tühistatud.",
    cancelledAlert: "Tehing on tühistatud Teie panga poolt.",
    cancelledBookings: "Tühistatud broneeringud",
    cancelledResultHeader: "Teie tasumine tühistati",
    checkConfirmBooking: "Kontrolli ja kinnita",
    child: "Laps",
    children: "Lapsed",
    childrenAges: "Laste vanused",
    city: "Linn",
    clear: "Kustuta",
    clearSelection: "Kustuta valitud tuba(d)",
    clearSelectionConfirmMessage:
      "Valitud toa(de) eemaldamiseks valige 'Jätka'.",
    close: "Sulge",
    code: "Kood",
    companyName: "Ettevõtte nimi",
    confirm: "Kinnita",
    continue: "Jätka",
    cottage: "Maakodu",
    country: "Riik",
    createNewBooking: "Loo uus broneering",
    creatingBookingFailed: "Broneeringu loomine ebaõnnestus",
    defaultRefused:
      "Teie tasumine lükati tagasi. Kontrollige, kas andmed on õigesti sisestatud vormi ning proovige uuesti.",
    departure: "Lahkumine",
    depositRequired: "Nõutav deposiit",
    disabledFacilities: "Erivajadustega inimestele mõeldud objektid",
    done: "Valmis",
    doubleBed: "Lai voodi",
    dueNow: "Tasuda kohe",
    email: "E-post",
    emailAddress: "E-posti aadress",
    estimatedTimeArrival: "Hinnanguline saabumisaeg",
    extraLargeBed: "Ekstra suur voodi",
    firstName: "Eesnimi",
    flexibleCancellation: "Paindlik tühistamine",
    freeCancellation: "Tasuta tühistamine",
    from: "alates",
    fullyPrepaid: "Täielikult ettemakstud",
    garanteeNow: "Garanteeritud kohe",
    guaranteedByCc: "Garanteeritud krediitkaardiga",
    guest: "Külaline",
    guestDetails: "Külalise andmed",
    guests: "Külalised",
    hotel: "Hotell",
    hotelRoom: "Hotellituba",
    infant: "Imik",
    infants: "Imikud",
    invalid: "Kehtetu",
    invalidDateRange: "Valige saabumise ja lahkumise kuupäev",
    invalidEmailError: "Sisestatud e-posti aadress ei ole kehtiv",
    invalidFormInputsMessage: "Vorm sisaldab kehtetuid või puuduvaid andmeid",
    invalidPhoneNumber: "Esimene märk peaks olema kas + või 0",
    invalidValue: "Vale väärtus",
    jacuzzi: "Mullivann",
    kingSizeBed: "King size voodi",
    login: "Logi sisse",
    loginErrorMessage:
      "Sisselogimine ebaõnnestus. Sisestatud e-posti aadressi ja PIN-koodi kombinatsioon on vale.",
    logout: "Logi välja",
    loadingDataFailed: "Andmete laadimine ebaõnnestus",
    name: "Nimi",
    nextRoom: "Järgmine tuba",
    night: "öö",
    nights: "Ööd",
    nights_plur: "ööd",
    noAvailableRoomsFound: "Vabu tube ei leitud",
    noBookingsFound: "Broneeringuid ei leitud.",
    noDataAvailable: "Andmed pole saadaval",
    noDataFound: "Andmeid ei leitud",
    noPackagesFound: "Pakette ei leitud",
    noPaymentMethodsAvailable: "Makseviisid pole saadaval.",
    noPetsAllowed: "Lemmikloomad pole lubatud",
    noRoomsFound: "Tube ei leitud",
    notAvailable: "Pole saadaval",
    noUpComingVisitsFound: "Ilmunud külastusi ei leitud.",
    numberOfAdults: "{0} täiskasvanud",
    numberOfChildren: "{0} last",
    numberOfInfants: "{0} imikuid",
    numberOfPersons: "{0} inimest",
    options: "Valikud",
    packages: "Paketid",
    pageNotFound: "Lehekülg ei leitud",
    pay: "Tasuda",
    payInHotel: "Tasumine kohapeal",
    payInHotelDescription: "Tasute saabumisel hotelli vastuvõtus.",
    payLater: "Tasuda hiljem",
    payLaterDescription:
      "Teie kaardilt ei võeta raha, vajame selle andmeid ainult broneeringu tagamiseks",
    payment: "Tasumine",
    payMentDetails: "Tasumise andmed",
    paymentGuarantee: "Makse garantii",
    payNow: "Tasuda kohe",
    payNowDescription: "Maksate kohe peale broneeringu lõpetamist.",
    payOptionsTitle: "Millal soovite maksta",
    pendingAlert: "Saime broneeringu kätte ja ootame tasumise lõpuleviimist.",
    pendingResultHeader: "Teie tasumine on ootel",
    perAdultPerNight: "Täiskasvanu/öö",
    perAdultPerStay: "Viibimine täiskasvanu kohta",
    percentagePerStay: "Protsent viibimise kohta",
    percentagePerStayPerAdult: "Protsent viibimise täiskasvanu kohta",
    perChildPerNight: "Laps/öö",
    perChildPerStay: "Viibimine lapse kohta",
    perInfantPerNight: "Imik/öö",
    perInfantPerStay: "Viibimine imiku kohta",
    perNight: "Öö kohta",
    perPersonPerNight: "Inimene/öö",
    perPersonPerStay: "Viibimine inimese kohta",
    person: "Inimene",
    persons: "Inimesed",
    perStay: "Viibimise kohta",
    perUnit: "Ühiku kohta",
    perUnitPerAdult: "Ühik täiskasvanu kohta",
    perUnitPerAdultPerNight: "Ühik/täiskasvanu/öö",
    perUnitPerNight: "Ühik/öö",
    petsAllowed: "Lemmikloomad lubatud",
    phoneNumber: "Telefoninumber",
    postalCode: "Postiindeks",
    privacyPolicy: "privaatsuspoliitika",
    processingBooking: "Broneeringu töötlemine",
    promoCode: "Promokood",
    queenSizeBed: "Queen size voodi",
    rate: "Hind",
    readLess: "Loe vähem",
    readMore: "Loe rohkem",
    reason: "Põhjus",
    refusalReason_2: "Tagasi lükatud",
    refusalReason_3: "Suunamine",
    refusalReason_4: "Omandaja viga",
    refusalReason_5: "Blokeeritud kaart",
    refusalReason_6: "Aegunud kaart",
    refusalReason_7: "Kehtetu summa",
    refusalReason_8: "Kehtetu kaardi number",
    refusalReason_9: "Väljastaja pole saadaval",
    refusalReason_10: "Ei ole toetatud",
    refusalReason_11: "3D pole autentitud",
    refusalReason_12: "Pole piisavalt saldol",
    refusalReason_14: "Omandaja pettus",
    refusalReason_15: "Tühistatud",
    refusalReason_16: "Ostmine tühistatud",
    refusalReason_17: "Kehtetu PIN",
    refusalReason_18: "PIN-katsete arv ületatud",
    refusalReason_19: "PIN-kontrollimine pole võimalik",
    refusalReason_20: "Pettus",
    refusalReason_21: "Pole esitatud",
    refusalReason_22: "PETTUSE TÕTTU TÜHISTATUD",
    refusalReason_23: "Tehing pole lubatud",
    refusalReason_24: "CVC lükati tagasi",
    refusalReason_25: "Piiratud kaart",
    refusalReason_26: "Autoriseeringu tühistamine",
    refusalReason_27: "Spetsiifiline tagasilükkamine",
    refusalReason_28: "Väljamakse summa ületatud",
    refusalReason_29: "Väljamaksete arv ületatud",
    refusalReason_31: "Väljastaja kahtlustab pettust",
    refusalReason_32: "AVS lükati tagasi",
    refusalReason_33: "Kaart vajab interneti PIN-koodi",
    refusalReason_34: "Kaardil pole arvelduskontot",
    refusalReason_35: "Kaardil pole hoiukontot",
    refusalReason_36: "Nõutav mobiilne PIN",
    refusalReason_37: "Kontaktivaba varulahendus",
    refusalReason_38: "Nõutav autentimine",
    refusalReason_39: "RReq pole DS-ilt saadud",
    refusedAlert: "Tehing lükati Teie panga poolt tagasi.",
    refusedResultHeader: "Teie tasumise lükati tagasi",
    removeOption: "Eemalda valik",
    removeOptionConfirmMessage: "'{0}' eemaldamiseks valige 'Jätka'.",
    removeRoom: "Eemalda tuba",
    removeRoomBookingConfirmMessage: "Selle toa eemaldamiseks valige 'Jätka'",
    removeRoomsGuestsRoomConfirmMessage:
      "Selle toa eemaldamiseks valige 'Jätka'",
    requiredFieldError: "Antud väli on kohustuslik.",
    reservation: "Broneering",
    retrieveDataErrorMessage: "Teie makse andmeid ei õnnestunud kätte saada.",
    retrieveDataErrorTitle: "Andmete hankimine ebaõnnestus",
    room: "Tuba",
    rooms: "Toad",
    roomTypeFilter: "Toa kategooria filter",
    search: "Otsing",
    searchCriteria: "Otsingu kriteeriumid",
    selectedDatesPerAdult: "Täiskasvanu kohta",
    selectedDatesPerChild: "Lapse kohta",
    selectedDatesPerInfant: "Imiku kohta",
    selectedDatesPerPerson: "Inimese kohta",
    selectedDatesPerStay: "Ühiku kohta",
    selectOptionsTitle: "Täiendage oma viibimist",
    selectPackage: "Valige pakettpakkumine",
    selectPackageWarning: "Selle paketi valimine seab Teie viibimise {0} ööks.",
    selectYourHotel: "Valige oma hotell",
    selectYourRoom: "Valige oma tuba",
    selfCatering: "Isetoitlustamine",
    sharedBathroom: "Jagatud vannituba",
    shower: "Dušš",
    showRoomImages: "Näita toapilte",
    showPackageImages: "Näita paketi pilte",
    singleBed: "Üheinimesevoodi",
    sofaBed: "Diivanvoodi",
    specialRequests: "Erisoovid",
    stayDates: "Viibimise kuupäevad",
    stayDatesLabel: "Saabumine ~ lahkumine",
    studio: "Stuudio",
    suite: "Sviit",
    surName: "Perekonnanimi",
    teaCoffee: "Tee ja kohv",
    termsConditions: "Tingimused ja määrused",
    terrace: "Terrass",
    thankYouP1:
      "Täname Teid broneeringu eest! Saate peagi oma kinnituse e-posti teel.",
    thankYouP2:
      "Palun võtke meiega ühendust, kui Teil on küsimusi või kui me saame täiendavalt aidata. Kohtumiseni!",
    thankYouP3: "Parimate soovidega,",
    thankYouTitle: "Aitäh",
    time: "Aeg",
    today: "Täna",
    toOverview: "Ülevaade",
    tv: "TV",
    twinBeds: "Kaks eraldi voodid",
    undefined: "Määratlemata",
    undefinedError: "Määratlemata viga",
    units: "Ühikud",
    unknown: "Tundmatu",
    upComingVisits: "Ilmuvad külastused",
    userLoginIntro:
      "Logige sisse oma e-posti ja PIN-koodiga, et hallata selle veebilehe kaudu loodud broneeringuid.",
    viewRates: "Hinnad",
    villa: "Villa",
    wifi: "WiFi",
    yourBookings: "Teie broneeringud",
    yourDetails: "Teie andmed",
    yourDiscount: "Teie soodustus: ",
    yourTotal: "Teie kogusumma",
  },
  "ru-RU": {
    "1adult": "1 взрослый",
    "1child": "1 ребенок",
    "1infant": "1 младенец",
    "1person": "1 человек",
    "404message": "Страница с url {0} не существует",
    add: "Добавить",
    additionalServices: "Дополнительные сборы и налоги",
    address: "Адрес",
    addRoom: "Добавить комнату",
    adult: "Взрослый",
    adults: "Взрослые",
    agreeTo: "Я согласен с",
    airconditioning: "Кондиционер",
    all: "Все",
    allRoomsSelected: "Вы уже выбрали свою(и) комнату(ы).",
    amount: "Сумма",
    apartment: "Апартаменты",
    arrival: "Прибытие",
    arrivalDate: "Дата прибытия",
    authorisedResultHeader: "Спасибо!",
    available: "Доступно",
    back: "Назад",
    backToPrevious: "Назад к предыдущей странице",
    balcony: "Балкон",
    bath: "Ванна",
    bathShower: "Ванна и душ",
    book: "Забронировать",
    bookerIsGuestLabel: "Я гость в этой комнате",
    bookingCreatedSuccessfully: "Бронирование успешно создано",
    bookingSummary: "Обзор бронирования",
    bunkBeds: "Двухъярусные кровати",
    businessBookingLabel: "Деловая поездка",
    cancel: "Отменить",
    cancelBooking: "Отменить бронирование",
    cancelBookingButton: "Отменить",
    cancelBookingConfirmMessage:
      "Выберите 'Продолжить', чтобы отменить это бронирование.",
    cancelBookingFailedMessage: "Отмена бронирования не удалась.",
    cancelBookingSuccessMessage: "Бронирование успешно отменено.",
    cancelledAlert: "Транзакция была отменена вашим банком.",
    cancelledBookings: "Отмененные бронирования",
    cancelledResultHeader: "Ваш платеж был отменен",
    checkConfirmBooking: "Проверить и подтвердить",
    child: "Ребенок",
    children: "Дети",
    childrenAges: "Возраст детей",
    city: "Город",
    clear: "Удалить",
    clearSelection: "Удалить выбранные комнаты",
    clearSelectionConfirmMessage:
      "Выберите 'Продолжить', чтобы удалить выбранные комнаты.",
    close: "Закрыть",
    code: "Код",
    companyName: "Название компании",
    confirm: "Подтвердить",
    continue: "Продолжить",
    cottage: "Коттедж",
    country: "Страна",
    createNewBooking: "Создать новое бронирование",
    creatingBookingFailed: "Не удалось создать бронирование ",
    defaultRefused:
      "Ваш платеж был отклонен. Пожалуйста, проверьте правильность ввода формы и повторите попытку.",
    departure: "Отправление",
    depositRequired: "Требуется депозит",
    disabledFacilities: "Для людей с ограниченными возможностями",
    done: "Готово",
    doubleBed: "Двуспальная кровать",
    dueNow: "К оплате сейчас",
    email: "Электронная почта",
    emailAddress: "Адрес электронной почты",
    estimatedTimeArrival: "Ожидаемое время прибытия",
    extraLargeBed: "Экстра большая кровать",
    firstName: "Имя",
    flexibleCancellation: "Гибкая отмена",
    freeCancellation: "Бесплатная отмена",
    from: "от",
    fullyPrepaid: "Полностью предоплачено",
    garanteeNow: "Гарантировать сейчас",
    guaranteedByCc: "Гарантировано кредитной картой",
    guest: "Гость",
    guestDetails: "Детали гостя",
    guests: "Гости",
    hotel: "Отель",
    hotelRoom: "Комната в отеле",
    infant: "Младенец",
    infants: "Младенцы",
    invalid: "Недействительно",
    invalidDateRange: "Пожалуйста, выберите дату заезда и выезда из отеля",
    invalidEmailError: "Введенный адрес электронной почты недействителен",
    invalidFormInputsMessage:
      "Форма содержит недействительные или отсутствующие данные",
    invalidPhoneNumber: "Первый символ должен быть либо +, либо 0",
    invalidValue: "Недействительное значение",
    jacuzzi: "Джакузи",
    kingSizeBed: "Кровать размера 'king size'",
    login: "Войти",
    loginErrorMessage:
      "Вход не удался. Введенная комбинация адреса электронной почты и PIN-кода неверна.",
    logout: "Выйти",
    loadingDataFailed: "Загрузка данных не удалась",
    name: "Имя",
    nextRoom: "Следующая комната",
    night: "ночь",
    nights: "ночи",
    nights_plur: "ночей",
    noAvailableRoomsFound: "Свободных комнат не найдено",
    noBookingsFound: "Бронирования не найдены",
    noDataAvailable: "Данные отсутствуют",
    noDataFound: "Данные не найдены",
    noPackagesFound: "Пакеты не найдены",
    noPaymentMethodsAvailable: "Способы оплаты недоступны.",
    noPetsAllowed: "Домашние животные не допускаются",
    noRoomsFound: "Комнаты не найдены",
    notAvailable: "Недоступно",
    noUpComingVisitsFound: "Ближайшие визиты не найдены",
    numberOfAdults: "{0} взрослых",
    numberOfChildren: "{0} детей",
    numberOfInfants: "{0} младенцев",
    numberOfPersons: "{0} человек",
    options: "Опции",
    packages: "Пакетные предложения",
    pageNotFound: "Страница не найдена",
    pay: "Заплатить",
    payInHotel: "Заплатить в отеле",
    payInHotelDescription: "Оплата на месте по прибытии на ресепшн отеля",
    payLater: "Заплатить позже",
    payLaterDescription:
      "С Вашей карты не снимаются средства, данные нужны только для гарантии Вашего бронирования.",
    payment: "Оплата",
    payMentDetails: "Детали оплаты",
    paymentGuarantee: "Гарантия оплаты",
    payNow: "Заплатить сейчас",
    payNowDescription: "Вы платите сразу после завершения бронирования",
    payOptionsTitle: "Когда Вы хотите заплатить?",
    pendingAlert: "Мы получили Ваше бронирование и ждем завершения оплаты",
    pendingResultHeader: "Ваш платеж в ожидании",
    perAdultPerNight: "Со взрослого за ночь",
    perAdultPerStay: "Со взрослого за пребывание",
    percentagePerStay: "Процент за пребывание",
    percentagePerStayPerAdult: "Процент за пребывание со взрослого",
    perChildPerNight: "С ребенка за ночь",
    perChildPerStay: "С ребенка за пребывание",
    perInfantPerNight: "С младенца за ночь",
    perInfantPerStay: "С младенца за пребывание",
    perNight: "За ночь",
    perPersonPerNight: "С человека за ночь",
    perPersonPerStay: "С человека за пребывание",
    person: "Человек",
    persons: "Люди",
    perStay: "За пребывание",
    perUnit: "За единицу",
    perUnitPerAdult: "За единицу с человека",
    perUnitPerAdultPerNight: "За единицу с человека за ночь",
    perUnitPerNight: "За единицу за ночь",
    petsAllowed: "Домашние животные допускаются",
    phoneNumber: "Номер телефона",
    postalCode: "Почтовый индекс",
    privacyPolicy: "политика конфиденциальности",
    processingBooking: "Обработка бронирования",
    promoCode: "Промо/Корпоративный/Скидочный код",
    queenSizeBed: "Кровать размера 'queen size'",
    rate: "Тариф",
    readLess: "Менее",
    readMore: "Более",
    reason: "Причина",
    refusalReason_2: "Отказано",
    refusalReason_3: "Перенаправление",
    refusalReason_4: "Ошибка приобретателя",
    refusalReason_5: "Заблокированная карта",
    refusalReason_6: "Просроченная карта",
    refusalReason_7: "Недействительная сумма",
    refusalReason_8: "Недействительный номер карты",
    refusalReason_9: "Эмитент недоступен",
    refusalReason_10: "Не поддерживается",
    refusalReason_11: "3D не аутентифицировано",
    refusalReason_12: "Недостаточно средств",
    refusalReason_14: "Мошенничество при покупке",
    refusalReason_15: "Отменено",
    refusalReason_16: "Покупатель отменил",
    refusalReason_17: "Недействительный PIN-код",
    refusalReason_18: "Превышено количество попыток ввода PIN-кода",
    refusalReason_19: "Валидация PIN-кода невозможна",
    refusalReason_20: "МОШЕННИЧЕСТВО",
    refusalReason_21: "Не отправлено",
    refusalReason_22: "МОШЕННИЧЕСТВО-ОТМЕНЕНО",
    refusalReason_23: "Транзакция не разрешена",
    refusalReason_24: "CVC отклонен",
    refusalReason_25: "Ограниченная карта",
    refusalReason_26: "Отзыв авторизации",
    refusalReason_27: "Отклонено из-за специфической ошибки",
    refusalReason_28: "Превышена сумма снятия",
    refusalReason_29: "Превышено количество снятий",
    refusalReason_31: "Эмитент подозревает мошенничество",
    refusalReason_32: "AVS отклонен",
    refusalReason_33: "Для карты требуется онлайн-PIN",
    refusalReason_34: "На карте нет текущего счета",
    refusalReason_35: "На карте нет сберегательного счета",
    refusalReason_36: "Требуется мобильный PIN-код",
    refusalReason_37: "Бесконтактный возврат",
    refusalReason_38: "Требуется аутентификация",
    refusalReason_39: "RReq не получен от DS",
    refusedAlert: "Транзакция была отклонена Вашим банком",
    refusedResultHeader: "Ваш платеж был отклонен",
    removeOption: "Удалить опцию",
    removeOptionConfirmMessage: "Выберите 'Продолжить', чтобы удалить '{0}'.",
    removeRoom: "Удалить комнату",
    removeRoomBookingConfirmMessage:
      "Выберите 'Продолжить', чтобы удалить эту комнату",
    removeRoomsGuestsRoomConfirmMessage:
      "Выберите 'Продолжить', чтобы удалить эту комнату",
    requiredFieldError: "Это поле обязательно для заполнения",
    reservation: "Бронирование",
    retrieveDataErrorMessage: "Мы не смогли получить данные для Вашего платежа",
    retrieveDataErrorTitle: "Не удалось получить данные",
    room: "Комната",
    rooms: "Комнаты",
    roomTypeFilter: "Фильтр категорий комнат",
    search: "Поиск",
    searchCriteria: "Критерии поиска",
    selectedDatesPerAdult: "Со взрослого ",
    selectedDatesPerChild: "С ребенка",
    selectedDatesPerInfant: "С младенца",
    selectedDatesPerPerson: "С человека",
    selectedDatesPerStay: "За единицу",
    selectOptionsTitle: "Улучшите Ваше пребывание",
    selectPackage: "Выберите пакетное предложение",
    selectPackageWarning:
      "Выбрав этот пакет, Вы сможете остановиться на {0} ночей",
    selectYourHotel: "Выберите Ваш отель",
    selectYourRoom: "Выберите Вашу комнату",
    selfCatering: "Самообслуживание",
    sharedBathroom: "Общая ванная комната",
    shower: "Душ",
    showRoomImages: "Показать изображения комнаты",
    showPackageImages: "Показать изображения пакета",
    singleBed: "Односпальная кровать",
    sofaBed: "Диван-кровать",
    specialRequests: "Особые запросы",
    stayDates: "Даты пребывания",
    stayDatesLabel: "Заезд ~ выезд",
    studio: "Студия",
    suite: "Свит",
    surName: "Фамилия",
    teaCoffee: "Чай и кофе",
    termsConditions: "Условия и положения",
    terrace: "Терраса",
    thankYouP1:
      "Благодарим Вас за бронирование! В ближайшее время подтверждение придет на электронную почту",
    thankYouP2:
      "Пожалуйста, свяжитесь с нами, если у Вас возникнут вопросы или потребуется дополнительная помощь. До встречи!",
    thankYouP3: "С уважением,",
    thankYouTitle: "Спасибо",
    time: "Время",
    today: "Сегодня",
    toOverview: "К обзору",
    tv: "Телевизор",
    twinBeds: "Две односпальные кровати",
    undefined: "Неопределено",
    undefinedError: "Неопределенная ошибка",
    units: "Единицы",
    unknown: "Неизвестно",
    upComingVisits: "Предстоящие визиты",
    userLoginIntro:
      "Войдите с Вашей электронной почтой и PIN-кодом, чтобы управлять бронированиями, созданными через этот сайт",
    viewRates: "Тарифы",
    villa: "Вилла",
    wifi: "WIFI",
    yourBookings: "Ваши бронирования",
    yourDetails: "Ваши данные",
    yourDiscount: "Ваша скидка: ",
    yourTotal: "Ваша общая сумма",
  },
};

function getMessage(locale, key) {
  if (!translations[locale]) {
    if (locale === "en-GB") {
      locale = "en-US";
    } else {
      locale = "en-US";
    }
  }
  const message = translations[locale][key];
  return message;
}

String.prototype.format = function () {
  var args = arguments;
  return this.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] !== "undefined" ? args[number] : match;
  });
};

export { getMessage };
