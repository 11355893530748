export default {
  namespaced: true,
  state: {
    appStyles: null,
  },
  mutations: {
    setAppStyles: (state, appStyles) => {
      state.appStyles = appStyles;
    },
  },
  actions: {
    setAppStyles: (context, appStyles) => {
      context.commit("setAppStyles", appStyles);
    },
  },
};
