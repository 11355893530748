<template>
  <v-main class="app-layout">
    <v-fade-transition mode="out-in">
      <router-view ref="view"> </router-view>
    </v-fade-transition>
    <v-footer v-if="!isMobile" app elevation="5" class="justify-center">
      <span class="text-body-2 text-center">
        SmartIBE powered by SmartHOTEL
        <span class="d-inline-block">
          <v-icon small color="grey darken-3">mdi-copyright</v-icon> (<a
            href="https://www.smarthotel.nl/privacy/"
            target="_blank"
            >privacy policy</a
          >)
        </span>
      </span>
    </v-footer>
  </v-main>
</template>

<script>
import isMobile from "@/mixins/isMobile";

export default {
  name: "AppLayout",
  mixins: [isMobile],
};
</script>
